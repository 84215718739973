import { Component, Input } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ModalController } from '@ionic/angular';
import { LangProvider } from '../../services/lang/lang';
import { HttprequestService } from 'src/app/services/httprequest.service';
import { SomeProvider } from 'src/app/services/some/some';
import { NavParams } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage-service.service';

@Component({
  selector: 'app-modale-elimina-prenotazione',
  templateUrl: './modale-elimina-prenotazione.page.html',
  styleUrls: ['./modale-elimina-prenotazione.page.scss'],
})
export class ModaleEliminaPrenotazionePage {
  chiudiLabel = "";
  confermaLabel = "";
  annullaLabel = "";

  @Input() tokenValue: string;
  @Input() idPrenotazione: string;
  @Input() idOrdine: string;
  @Input() dataPrenotazioneInizio: string;
  @Input() dataPrenotazioneFine: string;
  @Input() dataRistorante: string;
  @Input() tipoPrenotazione: string;


  constructor(
    public lingua: LangProvider,
    private http: HttprequestService,
    private storage: StorageService,
    public navParams: NavParams,
    private modalCtrl: ModalController,
    public myService: SomeProvider) {
    this.setLingua();
  }


  eliminaPrenotazione() {
    this.dismiss();

    const headers = new Headers({
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + this.tokenValue
    });

    let postData = {};

    if (this.tipoPrenotazione === "eliminaRistorante") {
      postData = {
        "idPrenotazioneRistorante": this.idPrenotazione,
        "idOrdine": this.idOrdine,
      };
      this.http.doPostApplicationJsonBearer(
        `${this.myService.apiUrl}${this.myService.serviziRest.deletePrenotazioneRist}`,
        postData,
        this.tokenValue
      ).subscribe(
        () => { },
        error => {
          console.log(error);
        }
      );
    } else if (this.tipoPrenotazione === "eliminaOmbrellone") {
      postData = {
        "idPrenotazioneOmbrellone": this.idPrenotazione,
        "idOrdine": this.idOrdine
      };
      this.http.doPostApplicationJsonBearer(
        `${this.myService.apiUrl}${this.myService.serviziRest.deletePrenotazioneOmbr}`,
        postData,
        this.tokenValue
      ).subscribe(
        () => { },
        error => {
          console.log(error);
        }
      );
    } else {
      console.log("Invalid tipo value.");
    }
  }


  setLingua(): void {
    if (this.lingua.languageSelected == 'IT') {
      this.chiudiLabel = this.lingua.chiudiLabel_IT;
      this.confermaLabel = this.lingua.confermaLabel_IT;
      this.annullaLabel = this.lingua.annullaLabelTornaIndietro_IT;

    } else if (this.lingua.languageSelected == 'EN') {
      this.chiudiLabel = this.lingua.chiudiLabel_EN;
      this.confermaLabel = this.lingua.confermaLabel_EN;
      this.annullaLabel = this.lingua.annullaLabelTornaIndietro_EN;
    } else if (this.lingua.languageSelected == 'FR') {
      this.confermaLabel = this.lingua.confermaLabel_FR;
      this.annullaLabel = this.lingua.annullaLabel_FR;
      this.chiudiLabel = this.lingua.chiudiLabel_FR;
    } else if (this.lingua.languageSelected == 'NL') {
      this.confermaLabel = this.lingua.confermaLabel_NL;
      this.annullaLabel = this.lingua.annullaLabel_NL;
      this.chiudiLabel = this.lingua.chiudiLabel_NL;
    }
  }


  dismiss(): void {
    this.storage.set('showModale', 'modaleVista').then((val) => {

    });

    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
}
