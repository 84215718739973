//import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttprequestService } from 'src/app/services/httprequest.service';
import { LoadingController, AlertController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { format, parseISO } from 'date-fns';
import { it } from 'date-fns/locale'
import { Subscription } from 'rxjs';
import { LangProvider } from '../../services/lang/lang';
import { StorageService } from '../storage-service.service';
import { serviziRest } from 'src/assets/serviziRest';
import { timeout, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

/*
  Generated class for the SomeProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({ providedIn: 'root' })
export class SomeProvider {
  apiUrl = "https://www.singlefinapp.it";
  condizioniVenditaPaganti: string = "";
  condizioniVenditaNonPaganti: string = "";

  privacyPolicyUrlEng: any;
  privacyPolicyUrlIta: any;
  hasStripeToken: boolean = false;
  isRistoranteUnoAttivo: boolean = false;
  isRistoranteDueAttivo: boolean = false;
  dataInizioFormattata: any;
  dataFineFormattata: any;

  liveStreamingData: string = ""; //fallback
  liveStreamingM3u: string = ""; //fallback
  isLiveStreamingDisponibileiOS: boolean = false;
  isLiveStreamingDisponibileAndroid: boolean = false;
  appInManutenzione: boolean;
  oraMaxPrenotabileContantiMattina: any = 10;
  oraMaxPrenotabileContantiPomeriggio: any = 15;
  provenienzaPage: string = "";
  errorMessageStripe: string = "";
  posizioneOrdine: string = ""; // qui, al cehckout, nel caso di ordine bar specifico dove consegnare i drink
  riepilogoOrdine = {};
  // variabili globali per utente
  datiUtente = { "password": "", "email": "", "nome": "", "cognome": "", "telefono": "", "fotoProfilo": "" };
  avvisoPagamentoContanti: boolean = false;
  carrelloTotale: number;
  fotoProfilo: any;
  serviziRest: any; // qui metter� tutte le chiamate ai servizi, così da poterli avere tutti in un unico file di properties.
  interval: any[];
  countDownDate: any;
  contaInterval: any;
  result = [];
  isCountDownStarted: boolean = false;
  isPagamentiOnlineAbilitato: boolean = false;
  isPagamentiContantiAbilitato: boolean = false;
  isPaypalDisponibile: boolean = false;
  observableVar: Subscription;
  isUserLoggedIn: boolean = false;
  isPlatformAndroid: boolean = false;
  isPlatformIos: boolean = false;
  isLoginFbIosAvailable: boolean = false;
  isLoginFbAndroidAvailable: boolean = false;
  isEliminaAccountAvailable: boolean = false;
  isGiorniMultipli: boolean = false;
  // oggetti carrello divisi per tipologia
  carrelloOmbrellone: any[];
  carrelloOrdiniBar: any[];
  carrelloRistorante: any[];
  carrelloParcheggio: any[];
  isInventarioMagazzinoEmpty: boolean = false;
  // flag che indica se ho elementi nel carrello o meno, per fare apparire opportuni messaggi a video
  emptyParcheggio: boolean = true;
  emptyRistorante: boolean = true;
  emptyOmbrelloni: boolean = true;
  emptyBar: boolean = true;
  isVersionObsoleta: boolean = false;

  // singole righe di oggetti di uno specifico tipo d'ordine con cui andrò a valorizzare il carrello.

  elementoParcheggio: any;
  elementoOmbrellone: any;
  elementoRistorante: any;
  elementoBar: any;


  // variabili per settare l'intervallo di disponibilità per le prenotazioni

  minDateParcheggio: string;
  minDateRistoranteUno: string;
  minDateRistoranteDue: string;
  minDateOmbrelloni: string;
  maxDateParcheggio: string;
  maxDateRistoranteUno: string;
  maxDateRistoranteDue: string;
  maxDateOmbrelloni: string;

  //
  isFasciaOrariaUnoSel: boolean;
  isFasciaOrariaDueSel: boolean;

  loading: any; // variabile per il loader che appare a video

  tokenValue: any;

  nav: any;

  percentualeServizio: number = 0; // per ora lo setto manualmente, dopo invece verr� inserito da DB. Calcola la commissione del servizio all'ombrellone, calcolata sul totale del carrello.

  idOmbrelloneBloccato: any;
  dataOmbrelloneBloccato: any;
  entraIsClicked: boolean = false;



  alertPrenotaOmbrelloneLabel = "";

  alertTempoScadutoLabel = "";
  alertUtentePassErratiLabel = "";
  alertUtentePassRiprovareLabel = "";

  alertLetturaDatiLabel = "";
  alertPagamentoInCorsoLabel = "";
  alertSalvataggioInCorsoLabel = "";
  alertErrorLabel = "";
  alertErrorMessageLabel = "";

  constructor(public lingua: LangProvider,
    private http: HttprequestService,
    private sanitizer: DomSanitizer,
    private alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    private storage: StorageService,
   /*  public app: App */) {
    this.getApiUrl(25000);
    this.setLingua();
    this.carrelloParcheggio = [];
    this.carrelloOmbrellone = [];
    this.carrelloRistorante = [];
    this.carrelloOrdiniBar = [];
    this.interval = [];


    this.contaInterval = 0;
    this.fotoProfilo = "";
    // this.datiUtente = [];
    this.elementoParcheggio = "";
    this.elementoOmbrellone = "";
    this.elementoRistorante = "";
    this.elementoBar = "";
    //console.log(this.fotoProfilo);
    // console.log(this.datiUtente);
    this.carrelloTotale = 0;
    this.isFasciaOrariaUnoSel = false;
    this.isFasciaOrariaDueSel = false;
    this.isCountDownStarted = false;

    this.idOmbrelloneBloccato = '';
    this.dataOmbrelloneBloccato = '';
    this.minDateOmbrelloni = '';
  }

  getApiUrl(time) {
    var headers = new Headers();

    this.http.doGet("https://www.singlefinapp.it/singlefin-ws/rest/public/users/getParametroInt?idParametro=1026").pipe(
      timeout(time),
      catchError(e => {
        this.apiUrl = "https://www.sunsetbeachapp.it";
        console.log(`ok apiUrl corrente timeout: ${this.apiUrl}`);
        return of(null);
      })
    )
      .subscribe(() => {
      }, error => {
        this.apiUrl = "https://www.sunsetbeachapp.it";
        console.log(`ok apiUrl corrente error: ${this.apiUrl}`);
        console.log(error);
      }).add(() => {
        this.initFirstSteps();
      })

  }


  initFirstSteps() {
    this.serviziRest = serviziRest;
    //  this.minDateParcheggio = this.creaData();     // serve a stabilire TODAY come primo giorno utile per prenotare il parcheggio
    this.minDateRistoranteUno = this.creaData();
    this.maxDateRistoranteUno = "2023-09-30"; //qui inseriro il giorno massimo per la prenotazione.
    this.minDateRistoranteDue = this.creaData();
    this.maxDateRistoranteDue = "2023-09-30"; //qui inseriro il giorno massimo per la prenotazione.


    //this.maxDateOmbrelloni = "2021-09-30";
    //console.log(this.minDateRistorante);  // serve a stabilire TODAY come primo giorno utile per prenotare il parcheggio
    this.storage.get('token').then((val) => {
      this.tokenValue = val;
      this.calcolaFineStagione();
      this.calcolaInizioStagione();
      this.calcolaFineStagioneRistorante();
      this.calcolaInizioStagioneRistorante();
      this.getPrivacyUrl();
    });
  }
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
  async stoppaLoader() {
    await this.loading.dismiss()
      .then(() => {
        this.loading = null;
      })
      .catch(e => console.log(e));
  }


  calcolaDataMinima(dataSplittata) {
    let today = this.creaData();
    let data = today.split('-');
    let anno = data[0];
    let mese = data[1];
    let giorno = data[2];
    today = anno + mese + giorno;
    if (parseInt(today) >= parseInt(dataSplittata)) {
      this.minDateOmbrelloni = this.creaData();
    }
  }
  calcolaDataMinimaRistorante(dataSplittata, id) {
    let today = this.creaData();
    let data = today.split('-');
    let anno = data[0];
    let mese = data[1];
    let giorno = data[2];
    today = anno + mese + giorno;
    if (parseInt(today) >= parseInt(dataSplittata)) {
      if (id == 1) {
        this.minDateRistoranteUno = this.creaData();
      }
      else {
        this.minDateRistoranteDue = this.creaData();
      }
    }
  }


  async isEliminaAccountDisponibile() {
    var headers = new Headers();
    this.http.doGet(this.apiUrl + "/singlefin-ws/rest/public/users/getParametroInt?idParametro=1026").subscribe(data => {
      this.isEliminaAccountAvailable = data == 1 ? true : false;
    }, error => {
      console.log(error);
    });


  }


  formatCheckoutDate(dataSceltaInizio, dataSceltaFine) {
    this.dataInizioFormattata = format(parseISO(dataSceltaInizio), 'dd MMM yyyy', { locale: it });
    this.dataFineFormattata = format(parseISO(dataSceltaFine), 'dd MMM yyyy', { locale: it });
  }


  getIsLiveStreamingDisponibile() {
    this.storage.get('token').then((val) => {
      this.tokenValue = val;
      //console.log(val);
      var headers = new Headers();
      headers.append('Authorization', 'Bearer ' + this.tokenValue);


      this.http.doGetApplicationJsonBearer(this.apiUrl + this.serviziRest.isLiveStreamingAvailableiOS, this.tokenValue).subscribe(data => {
        this.isLiveStreamingDisponibileiOS = data == 1 ? true : false;
      }, error => {
        console.log(error);
      });
      this.http.doGetApplicationJsonBearer(this.apiUrl + this.serviziRest.isLiveStreamingAvailableAndroid, this.tokenValue).subscribe(data => {
        this.isLiveStreamingDisponibileAndroid = data == 1 ? true : false;
        this.getLiveStreamingData();
      }, error => {
        console.log(error);
      });

    });


  }

  getLiveStreamingData() {
    this.storage.get('token').then((val) => {
      this.tokenValue = val;
      var headers = new Headers();
      headers.append("Accept", 'application/json');
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.tokenValue);


      if (this.isLiveStreamingDisponibileAndroid && this.isPlatformAndroid) {
        this.http.doGetApplicationJsonBearer(this.apiUrl + this.serviziRest.getLiveStreamingData, this.tokenValue).subscribe(data => {
          this.liveStreamingData = data;
        }, error => {
          console.log(error);
        });
      }
      if (this.isLiveStreamingDisponibileiOS && this.isPlatformIos) {
        this.http.doGetApplicationJsonBearer(this.apiUrl + this.serviziRest.getLiveStreamingM3u, this.tokenValue).subscribe(data => {
          this.liveStreamingM3u = data;
        }, error => {
          console.log(error);
        });
      }
    });
  }

  async calcolaInizioStagioneRistorante() {


    await this.http.doGetTextPublic(this.apiUrl + this.serviziRest.calcolaInizioStagioneRistoranteDue).subscribe((dataUno) => {
      if (dataUno != '' && dataUno != null && dataUno != undefined) {
        this.minDateRistoranteDue = dataUno;
        let dataSplittata: any = dataUno.split('-');
        let anno = dataSplittata[0];
        let mese = dataSplittata[1];
        let giorno = dataSplittata[2];
        dataSplittata = anno + mese + giorno;
        this.calcolaDataMinimaRistorante(dataSplittata, 2);
      } else {
        this.minDateRistoranteDue = this.creaData();
      }
    });

    await this.http.doGetTextPublic(this.apiUrl + this.serviziRest.calcolaInizioStagioneRistoranteDue).subscribe((dataDue) => {
      if (dataDue != '' && dataDue != null && dataDue != undefined) {
        this.minDateRistoranteDue = dataDue;
        let dataSplittata: any = dataDue.split('-');
        let anno = dataSplittata[0];
        let mese = dataSplittata[1];
        let giorno = dataSplittata[2];
        dataSplittata = anno + mese + giorno;
        this.calcolaDataMinimaRistorante(dataSplittata, 2);
      } else {
        this.minDateRistoranteDue = this.creaData();
      }
    });
  }
  async calcolaFineStagioneRistorante() {


    await this.http.doGetTextPublic(this.apiUrl + this.serviziRest.calcolaFineStagioneRistoranteUno).subscribe((dataUno) => {
      if (dataUno != '' && dataUno != null && dataUno != undefined) {
        this.maxDateRistoranteUno = dataUno;
      } else {
        this.maxDateRistoranteUno = "2021-09-30";
      }
    }
    );
  }
  async calcolaInizioStagione() {


    await this.http.doGetTextPublic(this.apiUrl + this.serviziRest.calcolaInizioStagione).subscribe((data) => {
      if (data != '' && data != null && data != undefined) {
        this.minDateOmbrelloni = data;
        let dataSplittata: any = data.split('-');
        let anno = dataSplittata[0];
        let mese = dataSplittata[1];
        let giorno = dataSplittata[2];
        dataSplittata = anno + mese + giorno;
        this.calcolaDataMinima(dataSplittata);
      } else {
        this.minDateOmbrelloni = this.creaData();
      }

    })
  }

  async calcolaFineStagione() {
    await this.http.doGetTextPublic(this.apiUrl + this.serviziRest.calcolaFineStagione).subscribe((data) => {
      if (data != '' && data != null && data != undefined) {
        this.maxDateOmbrelloni = data;
      } else {
        this.maxDateOmbrelloni = "2021-09-15";
      }
    })
  }

  isRistoranteAttivoFunc() {
    var headers = new Headers();
    // headers.append('Authorization', 'Bearer ' + this.tokenValue);


    this.http.doGet(this.apiUrl + "/singlefin-ws/rest/public/users/getParametroInt?idParametro=101").subscribe(data => {
      // this.myService.isPlatformIos = data == 1 ? true : false;
      this.isRistoranteUnoAttivo = data == 1 ? true : false;

    }, error => {
      console.log(error);
    });

    this.http.doGet(this.apiUrl + "/singlefin-ws/rest/public/users/getParametroInt?idParametro=102").subscribe(data => {
      // this.myService.isPlatformIos = data == 1 ? true : false;
      this.isRistoranteDueAttivo = data == 1 ? true : false;
      //console.log("maxGgAbbonamentoPrenotabili: " + this.maxGgAbbonamentoPrenotabili);
    }, error => {
      console.log(error);
    });


  }



  calcolaOrarioMaxPrenotazContanti() {
    var headers = new Headers();
    // headers.append('Authorization', 'Bearer ' + this.tokenValue);


    this.http.doGet(this.apiUrl + "/singlefin-ws/rest/public/users/getParametroInt?idParametro=1003").subscribe(data => {
      this.oraMaxPrenotabileContantiMattina = data;
      /*       console.log(this.oraMaxPrenotabileContantiMattina); */
    }, error => {
      if (error.status == 0) {
      } else {
      }

    });


    this.http.doGet(this.apiUrl + "/singlefin-ws/rest/public/users/getParametroInt?idParametro=1004").subscribe(data => {
      this.oraMaxPrenotabileContantiPomeriggio = data;
      /*    console.log(this.oraMaxPrenotabileContantiPomeriggio); */
    }, error => {
      if (error.status == 0) {
      } else {
      }

    });


  }




  getCondizioniVendita() {
    var headers = new Headers();
    // headers.append('Authorization', 'Bearer ' + this.tokenValue);


    this.http.doGetTextBearer(this.apiUrl + "/singlefin-ws/rest/public/users/getParametroString?idParametro=1039", this.tokenValue).subscribe(res => {
      let condizioniDiVendita = res.split('|');
      this.condizioniVenditaPaganti = this.lingua.languageSelected == 'IT' ? condizioniDiVendita[0] :
        (this.lingua.languageSelected == 'EN' ? condizioniDiVendita[1] :
          condizioniDiVendita[2]);
    }, error => {
      console.log(error);
    });

    this.http.doGetTextBearer(this.apiUrl + "/singlefin-ws/rest/public/users/getParametroString?idParametro=1038", this.tokenValue).subscribe(res => {
      let condizioniDiVenditaNonPaganti = res.split('|');
      this.condizioniVenditaNonPaganti = this.lingua.languageSelected == 'IT' ? condizioniDiVenditaNonPaganti[0] :
        (this.lingua.languageSelected == 'EN' ? condizioniDiVenditaNonPaganti[1] :
          condizioniDiVenditaNonPaganti[2]);
    }, error => {
      console.log(error);
    });

  }



  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase().trim() + string.slice(1).toLowerCase().trim();
  }



  /* Serve a creare una data nel formato AAAA-MM-GG partendo da un Sysdate, per poterlo far leggere alle sezioni contenenti un DATETIME Ionic (es. per prenotazione ombrelloni) */


  creaData() {
    var d = new Date();
    var mese = '' + (d.getMonth() + 1);
    var giorno = '' + d.getDate();
    var anno = d.getFullYear();
    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;
    return anno + "-" + mese + "-" + giorno;

  }

  creaDataTomorrow(today) {
    let data = today.split('-');
    let anno = data[0];
    let mese = data[1];
    let giorno = data[2];
    let d = new Date(anno, mese, giorno);
    mese = '' + (d.getMonth());
    giorno = '' + (d.getDate() + 1);
    anno = d.getFullYear();
    if (mese.length < 2) mese = '0' + mese;
    if (giorno.length < 2) giorno = '0' + giorno;
    return anno + "-" + mese + "-" + giorno;


  }


  /* Funzione per sbloccare un ombrellone, bloccato per procedere con l'acquisto */

  getUserStatus() {
    return this.isUserLoggedIn;
  }
  sbloccaOmbrellone(dataInizio, dataFine, idOmbrellone, idUser) {

    this.storage.get('token').then((val) => {
      this.tokenValue = val;
      var headers = new Headers();
      headers.append("Accept", 'application/json');
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.tokenValue);


      let postData = {
        "dataInizio": dataInizio,
        "dataFine": dataFine,
        "idOmbrellone": idOmbrellone,
        "idUser": idUser
      };
      this.http.doPostApplicationJsonBearer(this.apiUrl + this.serviziRest.sbloccaOmbrellone, postData, this.tokenValue)
        .subscribe(() => {
          //  this.tokenValue = data;

          for (let i in this.interval) {
            //      console.log("partito clearInterval da some.ts");
            clearInterval(this.interval[i]);
          }

        }, error => {
          if (error.status == 0) {
            this.alertAddressError();
          } else if (error.status == 400 || error.status == 401 || error.status == 403) {
            this.alertWrongCredentials();
          } else {
            this.alertGenericError();
          }
          //  console.log(error);
        })

    });

  }


  /* Gestione del timer per il timeout dell'acquisto di un ombrellone */


  countdown() {
    this.countDownDate = (new Date().getTime()) + 480000; //timeout a 8 minuti
    // this.countDownDate = (new Date().getTime()) + 10000; //timeout a 8 minuti

    this.contaInterval = setInterval(() => {

      this.eseguiSvuotaCarrello();
    }, 1000);

  }

  getTime(currentTime) {
    return ((currentTime.getDate()) <= 9 ? ('0' + (currentTime.getDate())) : (currentTime.getDate())) + "/"
      + ((currentTime.getMonth() + 1) <= 9 ? ('0' + (currentTime.getMonth() + 1)) : (currentTime.getMonth() + 1)) + "/"
      + ((currentTime.getFullYear()))
      + ", " + ((currentTime.getHours()) <= 9 ? ('0' + (currentTime.getHours())) : (currentTime.getHours())) + ":"
      + ((currentTime.getMinutes()) <= 9 ? ('0' + (currentTime.getMinutes())) : (currentTime.getMinutes()));
  }



  eseguiSvuotaCarrello() {

    // Get todays date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = this.countDownDate - now;
    // Time calculations for days, hours, minutes and d
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Output the result in an element with id="demo"
    var temp = document.getElementById("timer");
    if (temp != null) {
      temp.innerHTML = minutes + "m " + seconds + "s ";
    }

    // this.timerContatore = minutes + "m " + seconds + "s ";
    // If the count down is over, write some text
    if (distance < 0) {


      clearInterval(this.contaInterval);
      temp = document.getElementById("timer");
      if (temp != null) {
        document.getElementById("timer").innerHTML = "TIMEOUT";
      }
      this.svuotaCarrelloOmbrellone();
      //this.timerContatore = "TIMEOUT";
    }

  }

  stopCountdown() {
    var temp = document.getElementById("timer");
    if (temp != null) {
      temp.innerHTML = "";
    }
    //   console.log(this.contaInterval);
    clearInterval(this.contaInterval);
    this.isCountDownStarted = false;
    //   console.log(this.isCountDownStarted);
    //   console.log("sono dentro stopCountdown");
  }


  svuotaCarrelloOmbrellone() { // con questa funzione, elimino gli elementi di tipo Ombrellone dal carrello, allo scadere di un timeout (per ora settato a 10 minuti)
    //  console.log("sto svuotando gli elementi Ombrellone dal carrello per superamento dei minuti di tempo"); 
    for (let i in this.carrelloOmbrellone) {
      if (this.carrelloOmbrellone[i].idOmbrellone != null) {
        //    console.log(this.carrelloOmbrellone[i]);
        this.sbloccaOmbrellone(this.carrelloOmbrellone[i].dataInizio, this.carrelloOmbrellone[i].dataFine, this.carrelloOmbrellone[i].idOmbrellone, 1);
      }
    }

    for (let i in this.interval) {
      clearInterval(this.interval[i]);
    }
    this.svuotaOmbrelloneAlert();
    this.stopCountdown();
    this.carrelloOmbrellone = [];

    this.emptyOmbrelloni = true;
  }



  async svuotaOmbrelloneAlert() {
    const alert = await this.alertCtrl.create({
      header: this.alertPrenotaOmbrelloneLabel,
      message: this.alertTempoScadutoLabel,
      buttons: ['OK']
    });

    await alert.present();
  }




  async registrazioneUtenteAlert() {
    this.loading = await this.loadingCtrl.create({
      spinner: 'bubbles',
      duration: 10000,
      message: this.alertLetturaDatiLabel,
      translucent: true,
    });
    return await this.loading.present();
  }




  /* Parte relativa ai messaggi d'errore riguardanti il TOKEN d'accesso */
  async alertAddressError() {
    const alert = await this.alertCtrl.create({
      header: this.alertErrorLabel,
      message: this.alertErrorMessageLabel,
      buttons: ['OK']
    });

    await alert.present();
  }

  async alertError(message) {
    const alert = await this.alertCtrl.create({
      header: this.alertErrorLabel,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }


  async alertGenericError() {
    const alert = await this.alertCtrl.create({
      header: this.alertErrorLabel,
      message: this.alertErrorMessageLabel,
      buttons: ['OK']
    });

    await alert.present();
  }

  async alertWrongCredentials() {
    const alert = await this.alertCtrl.create({
      header: this.alertUtentePassErratiLabel,
      message: this.alertUtentePassRiprovareLabel,
      buttons: ['OK']
    });

    await alert.present();
  }


  async mostraLoader() {
    //console.log('chiamato mostra loader');
    this.loading = await this.loadingCtrl.create({
      spinner: 'bubbles',
      duration: 3000,
      message: this.alertLetturaDatiLabel,
      translucent: true,
    });
    return await this.loading.present();
  }



  async mostraLoaderNoTesti() {
    // console.log('chiamato mostra loader dentro some service');
    this.loading = await this.loadingCtrl.create({
      spinner: 'dots',
      duration: 3000,
      message: '',
      translucent: true,
    });
    return await this.loading.present();
  }

  async invioPagamentoLoader() {
    this.loading = await this.loadingCtrl.create({
      spinner: 'bubbles',
      duration: 2500,
      message: this.alertPagamentoInCorsoLabel,
      translucent: true,
    });
    return await this.loading.present();
  }
  async updateDatiLoader() {
    this.loading = await this.loadingCtrl.create({
      spinner: 'bubbles',
      duration: 4500,
      message: this.alertSalvataggioInCorsoLabel,
      translucent: true,
    });
    return await this.loading.present();
  }

  rimuoviLoader() {
    if (this.loading != null) {
      this.loading.dismiss().then();
    }
  }



  logout() {
    this.storage.set('showModale', '');
    this.isUserLoggedIn = false;
    this.storage.set('locale_lang', '').then((val) => {
    });



    if (this.observableVar != undefined) {
      this.observableVar.unsubscribe();
    }

    // Remove API token 
    this.storage.set('token', '').then((val) => {
      //   console.log("Stato Token (dentro logout): " + val);

    });

    this.storage.set('username', '').then((val) => {
      //   console.log("Stato username (dentro logout): " + val);

    });


  }

  getIsPagamentoPaypal() {
    this.storage.get('token').then((val) => {
      this.tokenValue = val;
      //console.log(val);
      var headers = new Headers();
      headers.append('Authorization', 'Bearer ' + this.tokenValue);

      this.http.doGetApplicationJsonBearer(this.apiUrl + this.serviziRest.isPaypalDisponibile, this.tokenValue).subscribe(data => {

        this.isPaypalDisponibile = (data == 1 ? true : false);
        //console.log("isPaypalDisponibile: " + this.isPaypalDisponibile);
      }, error => {
        // console.log(error.status);
        if (error.status == 0) {
          this.alertGenericError();
        } else {
          this.alertGenericError();
        }

        //console.log(error);
      });
    });
  }
  getIsPagamentoContanti() {
    this.storage.get('token').then((val) => {
      this.tokenValue = val;
      //console.log(val);
      var headers = new Headers();
      headers.append('Authorization', 'Bearer ' + this.tokenValue);

      this.http.doGetApplicationJsonBearer(this.apiUrl + this.serviziRest.pagaAncheContanti, this.tokenValue).subscribe(data => {

        this.isPagamentiContantiAbilitato = (data == 1 ? true : false);
        //console.log("isPagamentiOnlineAbilitato: " + this.isPagamentiOnlineAbilitato);
      }, error => {
        // console.log(error.status);
        if (error.status == 0) {
          this.alertGenericError();
        } else {
          this.alertGenericError();
        }

        //console.log(error);
      });
    });
  }

  getIsPagamentoOnline() {
    // console.log("sono dentro pag contanti");
    this.storage.get('token').then((val) => {
      this.tokenValue = val;
      //console.log(val);
      var headers = new Headers();
      headers.append('Authorization', 'Bearer ' + this.tokenValue);

      this.http.doGetApplicationJsonBearer(this.apiUrl + this.serviziRest.pagaSoloContanti, this.tokenValue).subscribe(data => {

        this.isPagamentiOnlineAbilitato = (data == 1 ? true : false);
        //console.log("isPagamentiOnlineAbilitato: " + this.isPagamentiOnlineAbilitato);
      }, error => {
        // console.log(error.status);
        if (error.status == 0) {
          this.alertGenericError();
        } else {
          this.alertGenericError();
        }

        //console.log(error);
      });
    });
  }



  getDatiUtente(email) {

    this.storage.get('token').then((val) => {

      this.tokenValue = val;
      // console.log(this.tokenValue);
      var headers = new Headers();
      headers.append("Accept", 'application/json');
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.tokenValue);

      let postData = {
        "email": email

      };

      this.http.doPostApplicationJsonBearer(this.apiUrl + this.serviziRest.getUtente, postData, this.tokenValue)
        .subscribe(data => {
          let tempUserdata = data;
          tempUserdata.stripeToken == 1 ? this.hasStripeToken = true : false;
          this.datiUtente.nome = tempUserdata.nome;
          this.datiUtente.cognome = tempUserdata.cognome;
          this.datiUtente.telefono = tempUserdata.telefono;
          this.datiUtente.email = email;
          this.datiUtente.fotoProfilo = tempUserdata.image;
          if (tempUserdata.image != null && tempUserdata.image != undefined && tempUserdata.image != '') {
            this.fotoProfilo = tempUserdata.image;
          } else {
            this.fotoProfilo = '';
          }

        }, error => {
          // console.log(error.status);
          if (error.status == 0) {
            this.alertAddressError();
          } else if (error.status == 400 || error.status == 401 || error.status == 403) {
            this.alertWrongCredentials();
          } else {
            this.alertGenericError();
          }

          //  console.log(error);
        });
    });

  }


  setDatiUtente() {
    this.updateDatiLoader();

    this.storage.get('token').then((val) => {

      this.tokenValue = val;
      // console.log(this.tokenValue);
      var headers = new Headers();
      headers.append("Accept", 'application/json');
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.tokenValue);

      let postData = {
        //    "email": datiUtente.email,
        //      "password" : "",
        "nome": this.datiUtente.nome,
        "cognome": this.datiUtente.cognome,
        "telefono": this.datiUtente.telefono,
        "image": this.datiUtente.fotoProfilo

      };


      this.http.doPostApplicationJsonBearer(this.apiUrl + this.serviziRest.setUtente, postData, this.tokenValue)
        .subscribe(data => {
          this.loading.dismiss();
          // console.log(data);
          // console.log("tutto ok aggiornamento dati profilo");
          this.fotoProfilo = this.datiUtente.fotoProfilo;
          this.getDatiUtente(this.datiUtente.email);

        }, error => {
          this.loading.dismiss();
          // console.log(error.status);
          if (error.status == 0) {
            this.alertAddressError();
          } else if (error.status == 400 || error.status == 401 || error.status == 403) {
            this.alertWrongCredentials();
          } else {
            this.alertGenericError();
          }

          // console.log(error);
        });
    });

  }


  setLingua() {
    if (this.lingua.languageSelected == 'IT') {
      this.alertPrenotaOmbrelloneLabel = this.lingua.alertPrenotaOmbrellone_IT;
      this.alertTempoScadutoLabel = this.lingua.alertTempoScaduto_IT;
      this.alertUtentePassErratiLabel = this.lingua.alertUtentePassErrati_IT;
      this.alertUtentePassRiprovareLabel = this.lingua.alertUtentePassRiprovare_IT;
      this.alertLetturaDatiLabel = this.lingua.alertLetturaDati_IT;
      this.alertPagamentoInCorsoLabel = this.lingua.alertPagamentoInCorso_IT;
      this.alertSalvataggioInCorsoLabel = this.lingua.alertSalvataggioInCorso_IT;
      this.alertErrorLabel = this.lingua.alertError_IT;
      this.alertErrorMessageLabel = this.lingua.alertErrorMessage_IT;


    } else if (this.lingua.languageSelected == 'EN') {
      this.alertPrenotaOmbrelloneLabel = this.lingua.alertPrenotaOmbrellone_EN;
      this.alertTempoScadutoLabel = this.lingua.alertTempoScaduto_EN;
      this.alertUtentePassErratiLabel = this.lingua.alertUtentePassErrati_EN;
      this.alertUtentePassRiprovareLabel = this.lingua.alertUtentePassRiprovare_EN;
      this.alertLetturaDatiLabel = this.lingua.alertLetturaDati_EN;
      this.alertPagamentoInCorsoLabel = this.lingua.alertPagamentoInCorso_EN;
      this.alertSalvataggioInCorsoLabel = this.lingua.alertSalvataggioInCorso_EN;
      this.alertErrorLabel = this.lingua.alertError_EN;
      this.alertErrorMessageLabel = this.lingua.alertErrorMessage_EN;
    } else if (this.lingua.languageSelected == 'FR') {
      this.alertPrenotaOmbrelloneLabel = this.lingua.alertPrenotaOmbrellone_FR;
      this.alertTempoScadutoLabel = this.lingua.alertTempoScaduto_FR;
      this.alertUtentePassErratiLabel = this.lingua.alertUtentePassErrati_FR;
      this.alertUtentePassRiprovareLabel = this.lingua.alertUtentePassRiprovare_FR;
      this.alertLetturaDatiLabel = this.lingua.alertLetturaDati_FR;
      this.alertPagamentoInCorsoLabel = this.lingua.alertPagamentoInCorso_FR;
      this.alertSalvataggioInCorsoLabel = this.lingua.alertSalvataggioInCorso_FR;
      this.alertErrorLabel = this.lingua.alertError_FR;
      this.alertErrorMessageLabel = this.lingua.alertErrorMessage_FR;
    } else if (this.lingua.languageSelected == 'NL') {
      this.alertPrenotaOmbrelloneLabel = this.lingua.alertPrenotaOmbrellone_NL;
      this.alertTempoScadutoLabel = this.lingua.alertTempoScaduto_NL;
      this.alertUtentePassErratiLabel = this.lingua.alertUtentePassErrati_NL;
      this.alertUtentePassRiprovareLabel = this.lingua.alertUtentePassRiprovare_NL;
      this.alertLetturaDatiLabel = this.lingua.alertLetturaDati_NL;
      this.alertPagamentoInCorsoLabel = this.lingua.alertPagamentoInCorso_NL;
      this.alertSalvataggioInCorsoLabel = this.lingua.alertSalvataggioInCorso_NL;
      this.alertErrorLabel = this.lingua.alertError_NL;
      this.alertErrorMessageLabel = this.lingua.alertErrorMessage_NL;
    }
  }

  getPrivacyUrl() {
    var headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.tokenValue);

    this.http.doGetTextBearer(this.apiUrl + this.serviziRest.getPrivacyUrlIta, this.tokenValue).subscribe(data => {
      this.privacyPolicyUrlIta = this.sanitizer.bypassSecurityTrustResourceUrl(data);
    }, error => {
      console.log(error);
    });

    this.http.doGetTextBearer(this.apiUrl + this.serviziRest.getPrivacyUrlEng, this.tokenValue).subscribe(data => {
      this.privacyPolicyUrlEng = this.sanitizer.bypassSecurityTrustResourceUrl(data);
    }, error => {
      console.log(error);
    });
  }
}
