import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { LangProvider } from 'src/app/services/lang/lang';
import { Storage } from '@ionic/storage';
import { SomeProvider } from 'src/app/services/some/some';
import { StorageService } from 'src/app/services/storage-service.service';
@Component({
  selector: 'app-modale-informativa',
  templateUrl: './modale-informativa.page.html',
  styleUrls: ['./modale-informativa.page.scss'],
})
export class ModaleInformativaPage {
  chiudiLabel = "";

  privacyPolicyIta: string;
  privacyPolicyEng: string;

  constructor(
    public lingua: LangProvider,
    private router: Router,
    private storage: StorageService,
    private myService: SomeProvider,
    private modalCtrl: ModalController) {
    this.setLingua();
  }

  ionViewWillEnter() {
    this.privacyPolicyIta = this.myService.privacyPolicyUrlIta;
    this.privacyPolicyEng = this.myService.privacyPolicyUrlEng;
  }

  setLingua() {
    if (this.lingua.languageSelected == 'IT') {
      this.chiudiLabel = this.lingua.chiudiLabel_IT;
    } else if (this.lingua.languageSelected == 'EN') {
      this.chiudiLabel = this.lingua.chiudiLabel_EN;
    } else if (this.lingua.languageSelected == 'FR') {
      this.chiudiLabel = this.lingua.chiudiLabel_FR;
    }
  }

  goToOrdiniBar(): void {
    console.log('dismetto modale');
    this.dismiss();
    this.router.navigate(['/ordini-bar']);
  }

  dismiss() {
    this.storage.set('showModale', 'modaleVista');

    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
}
