import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { timeout, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HttprequestService {


  constructor(private http: HttpClient) {

  }

  doGet = (url: string) => {
    return this.http.get<any>(url);
  };

  doGetTimeout = (url: string) => {
    return this.http.get<any>(url).pipe(
      timeout(25000),
      catchError(() => {
        return of(1);
      })
    );
  };


  doGetTextBearer = (url: string, token: string) => {

    const headers = {
      'content-type': "application/json",
      'accept': 'application/json, text/plain, */*',
      'Authorization': 'Bearer ' + token
    };

    return this.http.get(
      url,
      { responseType: 'text', headers })


  };


  doGetTextPublic = (url: string) => {
    const headers = {
      'content-type': "application/json",
      'accept': 'application/json, text/plain, */*',
    };

    return this.http.get(
      url,
      { responseType: 'text', headers })

  };

  doGetApplicationJsonBearer = (url: string, token: string) => {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': "application/json",
      'Authorization': 'Bearer ' + token
    };

    return this.http.get<any>(url, { headers });

  };


  doPostApplicationJson = (url: string, params: any) => {
    const headers = {
      'content-type': "application/json",
      'accept': 'application/json',
    };

    return this.http.post<any>(url, params, { headers });
  };

  doPostApplicationTextAll = (url: string, params: any) => {
    const headers = {
      'content-type': "application/json",
      'accept': 'application/json, text/plain, */*',
    };

    return this.http.post(
      url,
      params,
      { responseType: 'text', headers })
  };



  doPostApplicationJsonBearer = (url: string, params: any, token: string) => {

    const headers = {
      "Accept": 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
    return this.http.post<any>(url, params, { headers });
  };

  doPostTextBearer = (url: string, params: any, token: string) => {

    const headers = {
      "Accept": 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
    return this.http.post(
      url,
      params,
      { responseType: 'text', headers })
  };

};



/* doGetApplicationJson = (url: string) => {
  let headers = {
    'content-type': "application/json",
    'accept': 'application/json',
  }
  const options = {
    url: url,
    headers: headers
  };

  return this.http.get<any>(url);

};
*/
/*
  doGetText = (url: string) => {
    let headers = {
      'content-type': "application/json",
      'accept': 'text/plain',
    }
    const options = {
      url: url,
      headers: headers
    };
 
    return this.http.get<any>(url);
 
  }; */





