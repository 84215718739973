//import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { StorageService } from '../storage-service.service';

@Injectable({ providedIn: 'root' })
export class LangProvider {

  infoLabel = "";
  ordiniLabel = "";
  profiloLabel = "";
  prenotaLabel = "";
  inPreparazioneLabel = "";
  menuDelGiornoLabel = "";
  riepilogoLabel = "";
  avantiLabel = "";

  prenotaTabBar = "";

  costoLabel_IT = "Costo";
  costoPagamentoOnlineLabel_IT = "Pagamento Online";
  costoLabel_EN = "Price";
  costoPagamentoOnlineLabel_EN = "Online Payment";
  costoLabel_FR = "Prix";
  costoPagamentoOnlineLabel_FR = "Paiement Online";
  modificaRicercaLabel_IT = "Modifica Ricerca";
  modificaRicercaLabel_EN = "Modify your search";
  modificaRicercaLabel_FR = "modifier la recherche";
  modificaRicercaLabel_NL = "modifier la recherche";
  condizioniDiVenditaLabel_IT = "Confermo di aver preso visione delle condizioni di vendita";
  condizioniDiVenditaLabel_FR = "J'ai lu les conditions de vente";
  condizioniDiVenditaLabel_EN = "I have read the conditions of sale";
  ristoranteServizioSospesoLabel_IT = "Il servizio ristorante è temporaneamente sospeso."
  ristoranteServizioSospesoLabel_EN = "The restaurant service is temporarily suspended."
  ristoranteServizioSospesoLabel_FR = "Le service de restauration est temporairement suspendu."
  chiudiLabel_IT = "Chiudi";
  chiudiLabel_EN = "Close";
  chiudiLabel_FR = "Ferme";
  chiudiLabel_NL = "Close";
  languageSelected = "IT"; // lingua default, viene sostituito dal codice della lingua selezionata 
  flagLanguageSelected = "It"; // lingua delle bandierine da far vedere a video
  annullaAcquistoLabel_IT = "Annulla Acquisto";
  annullaAcquistoLabel_EN = "Cancel Purchase";
  annullaAcquistoLabel_FR = "Annuler l'achat";
  annullaAcquistoLabel_NL = "Annuler l'achat";
  checkoutCarrelloConcludiOrdineLabel_EN = "Complete your order";
  checkoutCarrelloConcludiOrdineLabel_FR = "Terminez le commande";
  checkoutCarrelloConcludiOrdineLabel_NL = "Complete order";
  checkoutCarrelloConcludiOrdineLabel_IT = "Concludi Prenotazione";
  orarioLimiteLabel__EN = 'With cash payment it is necessary to arrive by 10am for "full day" and "morning" and by 3pm for "afternoon"';
  orarioLimiteLabel__FR = "Avec le paiement en espèces, il est nécessaire d'arriver avant 10h00 pour la `` journée complète '' et le `` matin '' et avant 15h00 pour `` l'après-midi ''"
  orarioLimiteLabel__IT = "Con pagamento in contanti è necessario presentarsi entro le ore 10 per 'intera giornata' e 'mattina' ed entro le ore 15 per il 'pomeriggio'";
  orarioLimiteLabel__NL = "Con pagamento in contanti è necessario presentarsi entro le ore 10 per 'intera giornata' e 'mattina' ed entro le ore 15 per il 'pomeriggio'";
  prenotazioneDaSaldareLabel_IT = "Prenotazione da saldare";
  prenotazioneSaldataLabel_IT = "Prenotazione saldata";
  prenotazioneDaSaldareLabel_FR = "réservation à payer";
  prenotazioneSaldataLabel_FR = "réservation payée";
  prenotazioneDaSaldareLabel_EN = "reservation to be paid";
  prenotazioneSaldataLabel_EN = "reservation paid";
  prenotazioneDaSaldareLabel_NL = "reservation to be paid";
  prenotazioneSaldataLabel_NL = "reservation paid";
  /* LINGUA ITALIANA */
  appUpdateLabel_IT = "Aggiorna App";
  prenotaAsportoLabel_IT = "La prenotazione è per il giorno corrente";
  sdraioSingolaLabel_IT = "Sdraio";
  sdraioMultipleLabel_IT = "Sdraio";
  lettinoSingoloLabel_IT = "Lettino";
  lettiniMultipliLabel_IT = "Lettini";
  accessoriExtraGazeboLabel_IT = "1 Sedia Regista 1 Amaca 1 Tavolo";
  aggiornaDatiLabel_IT = "Aggiorna Profilo";
  aggiungiCarrelloLabel_IT = "Aggiungi al carrello";
  alDateLabel_IT = "A:";
  alertAppObsoleta_IT = "Versione App Obsoleta";
  alertAppObsoletaMess_IT = "Aggiornare l\'applicazione per continuare ad utilizzarla.";
  alertAppInAggiornamento_IT = "Manutenzione in corso";
  alertAppInAggiornamentoMess_IT = "Sarà possibile utilizzare l'app al più presto.";
  alertDopoLeDodiciLabel_IT = "E\' possibile prenotare solo per cena o per i giorni successivi";
  alertDopoLeUndiciLabel_IT = "Dopo le ore 11 e\' possibile prenotare solo per i giorni successivi";
  alertDopoLeVentiLabel_IT = "E\' possibile prenotare solo per i giorni successivi'";
  alertError_IT = "Oops! Qualcosa non ha funzionato!";
  alertErroreDateLabel_IT = "La data di fine non può essere inferiore a quella di inizio";
  alertErroreLabel_IT = "Errore sulle date";
  alertErrorMessage_IT = "Riprova :) Se l'errore persiste, prova ad effettuare il logout e riprovare";
  alertGiorniPassatiLabel_IT = "Non e\' possibile prenotare per giorni passati";
  alertLetturaDati_IT = "Lettura dati in corso.";
  alertMaxOmbrelloni_IT = "E\' possibile riservare al massimo 5 ombrelloni alla volta.";
  alertOmbrNonDispLabel_IT = "L\'ombrellone selezionato non e\' piu\' disponibile, sceglierne un altro.";
  alertPagamentoInCorso_IT = "Pagamento in corso.";
  alertPrenotaOmbrellone_IT = "Prenotazione Ombrellone";
  alertPrenotaRistorante_IT = "Prenotazione Ristorante";
  alertSalvataggioInCorso_IT = "Salvataggio dati in corso.";
  alertTempoScaduto_IT = "Tempo scaduto per la prenotazione degli ombrelloni riservati: il carrello e\' stato svuotato.";
  alertUtentePassErrati_IT = "E-Mail o password errate";
  alertUtentePassRiprovare_IT = "Verificare i dati immessi e riprovare.";
  annullaLabel_IT = "Annulla";
  annullaLabelTornaIndietro_IT = "Torna Indietro";
  annullaLabelTornaIndietro_FR = "RETOURNER";
  avantiLabel_IT = "AVANTI";
  barSpiaggiaLabel_IT = "Ordini Spiaggia";
  calcoloPrezzoLabel_IT = "Calcolo prezzo in corso...";
  carrelloLabel_IT = "Vai al carrello";
  cartaCredito_aggiungiCartaLabel_IT = "Aggiungi carta";
  cartaCredito_aggiungiCartaPagamentoLabel_IT = "AGGIUNGI O MODIFICA UNA CARTA";
  cartaCredito_cambiaCartaLabel_IT = "Cambia carta";
  cartaCredito_cartaLabel_IT = "Carta";
  cartaCredito_consegnaLabel_IT = "Consegna: ";
  cartaCredito_errorMsgLabel_IT = "Siamo spiacenti, l'ordine non è andato a buon fine.";
  cartaCredito_esitoPagamentoLabel_IT = "Esito Pagamento";
  cartaCredito_modificaCartaLabel_IT = "Modifica Carta di Credito";
  cartaCredito_modificaCartaPagamentoLabel_IT = "Modifica carta di pagamento";
  cartaCredito_nessunaCartaLabel_IT = "Nessuna carta disponibile";
  cartaCredito_numeroCartaLabel_IT = "Numero Carta";
  cartaCredito_ordineCompletatoLabel_IT = "ORDINE COMPLETATO";
  cartaCredito_ordineMsgLabel_IT = "In caso di prenotazione ombrellone effettuata in contanti e' necessario presentarsi entro le ore 12.";
  cartaCredito_pagaConCartaLabel_IT = "Paga con carta di credito";
  cartaCredito_pagaOraLabel_IT = "PAGA ORA";
  cartaCredito_rimuoviCartaLabel_IT = "Rimuovi carta";
  cartaCredito_ritentaOrdineLabel_IT = "RITENTA ACQUISTO";
  cartaCredito_scadenzaCartaLabel_IT = "Scadenza";
  cartaCredito_tornaHomeLabel_IT = "TORNA ALLA HOME";
  cartaCredito_totaleOrdineLabel_IT = "Totale Ordine: ";
  checkoutCarrelloCartaCreditoLabel_IT = "Carta di Credito";
  checkoutCarrelloConcludiPagamentoLabel_IT = "Concludi pagamento";
  checkoutCarrelloConsegnaOrdineBarLabel_IT = "Dove consegnare l'ordine?";
  checkoutCarrelloConsegnaOrdineMsgLabel_IT = "Inserire dove consegnare l'ordine";
  checkoutCarrelloContantiLabel_IT = "Contanti";
  checkoutCarrelloContinuaAcquistiLabel_IT = "Continua acquisti";
  checkoutCarrelloDataLabel_IT = "Data:";
  checkoutCarrelloLabel_IT = "Carrello";
  checkoutCarrelloMetodoPagamentoLabel_IT = "Seleziona metodo pagamento:";
  checkoutCarrelloNoPagamentoLabel_IT = "Non è necessario alcun pagamento";
  checkoutCarrelloNrLettiniLabel_IT = "Numero Lettini:";
  checkoutCarrelloNrSdraioLabel_IT = "Numero Sdraio:";
  checkoutCarrelloNumeroPostiRistoranteLabel_IT = "Numero Posti Riservati:";
  checkoutCarrelloOrarioLabel_IT = "Orario:";
  checkoutCarrelloOrdineBarLabel_IT = "Ordine Bar:";
  checkoutCarrelloOrdineOmbrelloniTimeoutLabel_IT = "Ordine Ombrelloni";
  checkoutCarrelloPagaLabel_IT = "Paga";
  checkoutCarrelloPlaceHolderConsegnaLabel_IT = "Esempio: Ombrellone A11";
  checkoutCarrelloPrenotatoLabel_IT = "Riservato:";
  checkoutCarrelloQuandoLabel_IT = "Quando:";
  checkoutCarrelloRistoranteLabel_IT = "Ordine Ristorante:";
  checkoutCarrelloSvuotaCarrelloLabel_IT = "Svuota il	carrello";
  checkoutCarrelloTotaleLabel_IT = "Totale:";
  checkoutCarrelloVuotoLabel_IT = "Il carrello è vuoto.";
  ciaoUserLabel_IT = "Ciao";
  confermaLabel_IT = "Conferma";
  confermaOmbrellone_Label_IT = "Conferma la tua prenotazione";
  confermaRistorante_Label_IT = "Conferma la tua prenotazione";
  dalDateLabel_IT = "Da:";
  dataLabel_IT = "Data:";
  dayNamesLabel_IT = "Domenica -, Lunedi -, Martedi -, Mercoledi -, Giovedi -, Venerdi -, Sabato -";
  dogLabel_IT = "Accesso ai cani";
  fasciaOrariaLabel_IT = "Orario?";
  finalitaTrattamento_IT = "Finalità Trattamento Dati";
  giornataLabel_IT = "Intera Giornata";
  giorniMultipliLabel_IT = "Giorni Multipli";
  giorniPrenotatiLabel_IT = "Giorni prenotati:";
  hoDueCaniLabel_IT = "Ho due cani";
  home_dataDifferente_IT = "Scegliere una data differente";
  home_dayNames_IT = "Dom -, Lun -, Mar -, Mer -, Gio -, Ven -, Sab -";
  home_disponibiliLabel_IT = "Disponibili:";
  home_monthShortNames_IT = "Gen, Feb, Mar, Apr, Mag, Giu, Lug, Ago, Set, Ott, Nov, Dic";
  home_postiLabel_IT = "Posti";
  home_prenotaOmbrellone_IT = "Prenota un ombrellone";
  home_prenotaOnline_IT = "Prenota Online";
  home_prenotaRistorante_IT = "Prenota il ristorante"
  home_ristoranteLabel_IT = "RISTORANTE";
  home_selezionaGiorno_IT = "Che Giorno?";
  homeCena_IT = "Cena";
  homeNumeroCoperti_IT = "Numero coperti?";
  homePranzo_IT = "Pranzo";
  homePranzoOCena_IT = "Vuoi prenotare a pranzo o cena?";
  homePrenotaLabel_IT = "Prenota";
  homeScegliNumeroCoperti_IT = "Scegliere il numero di coperti";
  homeSelezionaOrario_IT = "Seleziona l'orario";
  homeSelezionaOrarioMsg_IT = "Per favore scegliere un orario";
  homeServizioBar_IT = "Il servizio bar sarà attivato a breve.";
  infoAccessLabel_IT = "Accessibilità";
  infoComunicazioniLabel_IT = "Per comunicazioni e segnalazioni, scrivere a ";
  infoCusciniLabel_IT = "Cuscini";
  infoDocceLabel_IT = "Docce";
  infoFilaLabel_IT = "Fila";
  infoLabel_IT = "Info";
  infonNoleggioLabel_IT = "Noleggio";
  infoParcheggioLabel_IT = "Parcheggio";
  infoPrezziLabel_IT = "Prezzi";
  infoRistoranteLabel_IT = "Ristorante";
  infoServiziLabel_IT = "I nostri servizi";
  inPreparazioneLabel_IT = "in preparazione";
  inserisciDatiLabel_IT = "Inserisci i dati:";
  insertName_IT = "Inserire il proprio nome";
  insertSurname_IT = "Inserire il proprio cognome";
  invalidPhone_IT = "Lunghezza errata: cellulare non valido.";
  isCarrelloIbridoLabel_IT = "NON E' POSSIBILE ACQUISTARE CONTEMPORANEAMENTE SERVIZI ED OMBRELLONI, EFFETTUARE IL PAGAMENTO DI UN ELEMENTO ALLA VOLTA";
  lettinoAggiuntivoLabel_IT = "Lettino aggiuntivo";
  lettinoLabel_IT = "LETTINO";
  listaCaniLabel_IT = "Lista Cani:";
  listaOrdiniLabel_IT = "Ordini";
  lunghMaxDodCar_IT = "Lunghezza massima 12 caratteri";
  lunghMaxVentiCar_IT = "Lunghezza massima 20 caratteri";
  lunghMaxVentiCinqueCar_IT = "Lunghezza massima 25 caratteri";
  lunghMinDueCar_IT = "Lunghezza minima 2 caratteri";
  lunghMinSeiCar_IT = "Lunghezza minima 6 caratteri";
  lunghMinTreCar_IT = "Lunghezza minima 3 caratteri";
  mattinaLabel_IT = "Mattina";
  maxPostiPren_IT = "Numero massimo posti prenotabili:";
  menuDelGiornoLabel_IT = "Menu del giorno";
  menuPubblicoChiudiLabel_IT = "CHIUDI";
  modificaProfiloLabel_IT = "Modifica Profilo";
  monthLongNames_IT = "Gennaio, Febbraio, Marzo, Aprile, Maggio, Giugno, Luglio, Agosto, Settembre, Ottobre, Novembre, Dicembre";
  nessunoLabel_IT = "NESSUNO";
  nessunOrdineLabel_IT = "Nessun ordine presente";
  nienteSdraioLabel_IT = "Non ci sono sdraio disponibili.";
  nomeCaneDue_IT = "Nome Cane Due";
  nomeCaneUno_IT = "Nome Cane Uno";
  numeroCopertiLabel_IT = "Numero Coperti:";
  numeroLettiniLabel_IT = "Numero di lettini:";
  numeroSdraioLabel_IT = "Numero di sdraio:";
  numeroSeduteLabel_IT = "Numero di sedute";
  numeroServiziAggLabel_IT = "Numero servizi aggiuntivi: ";
  obbligoDueSdraioLabel_IT = "E' obbligatorio prenotare almeno 2 sdraio.";
  obbligoUnaSdraioLabel_IT = "E' obbligatorio prenotare almeno 1 sdraio.";
  ombrelloneLabel_IT = "Ombrellone";
  onlyLetters_IT = "Inserire solo lettere";
  onlyNumbers_IT = "Inserire solo numeri";
  ordineEffettuatoLabel_IT = "Ordine Effetuato";
  ordineNrLabel_IT = "Prenotazione N.";
  pomeriggioLabel_IT = "Pomeriggio";
  posizioneLabel_IT = "Posizione:";
  postiDisp_IT = "Non sono rimasti posti disponibili.";
  pranzoCena_IT = "Pranzo o Cena?";
  prenotaPostoLabel_IT = "Riserva un ombrellone";
  prenotaTabBar_IT = "Prenota";
  prenotazioneLabel_IT = "Pranzo o Cena?"
  proseguiGuestLabel_IT = "Prosegui come ospite";
  resetPass_cambiaPassword_IT = "Modifica Password";
  resetPass_confermaCambioLabel_IT = "Riceverai una email di conferma all'indirizzo indicato";
  resetPass_diversePassword_IT = "Le password inserite sono diverse";
  resetPass_emailNonValida_IT = "Indirizzo E-Mail non valido";
  resetPass_inserireMail_IT = "Per favore inserire la propria e-mail";
  resetPass_inserirePassword_IT = "Per favore inserire una password";
  resetPass_inserisciDatiLabel_IT = "Inserisci i tuoi dati per resettare la password:";
  resetPass_modificaLabel_IT = "Modifica la tua password";
  resetPass_msgConfermaLabel_IT = "Clicca sulla e-mail di conferma per accedere con la nuova password  ";
  resetPass_reinserirePassword_IT = "Per favore ridigitare la password";
  resetPass_riscriviLabel_IT = "Riscrivi";
  resetPass_spamLabel_IT = "(Controllare la posta indesiderata)";
  riepilogoLabel_IT = "riepilogo";
  ristorante_confermaLabel_IT = "Conferma Prenotazione";
  ristorante_disponibiliLabel_IT = "Disponibili:";
  ristorante_postiLabel_IT = "Posti";
  ristoranteLabel_IT = "Ristorante";
  scegliDataLabel_IT = "Per favore scegliere una data";
  scegliOmbrelloLabel_IT = "Scegli un Ombrellone";
  sdraioAggiuntivaLabel_IT = "Sdraio aggiuntiva";
  sdraioAggLabel_IT = "SDRAIO";
  sdraioLabel_IT = "Sdraio:";
  seduteAggLabel_IT = "SEDUTE AGGIUNTIVE";
  signup_account_IT = "Creazione Account";
  signup_cognome_IT = "Cognome";
  signup_completa_messDati_IT = "Per poter procedere è necessario inserire <br> tutti i dati richiesti."
  signup_completaRegistrazione_IT = "Completa Registrazione";
  signup_emailNonValida_IT = "Indirizzo E-Mail non valido";
  signup_inserireMail_IT = "Per favore inserire la propria e-mail";
  signup_inserirePassword_IT = "Per favore inserire una password";
  signup_inserireTelefono_IT = "Inserire il proprio numero di telefono";
  signup_nome_IT = "Nome";
  signup_presoVisioneInformativa_IT = "Dichiaro di aver preso visione dell'informativa <br>sul Trattamento dei dati personali";
  signup_presoVisioneTrattamento_IT = "Dichiaro di aver preso visione dell'informativa <br>sulla Finalità del trattamento";
  signup_telefono_IT = "Telefono";
  tagliaCaneLabel_IT = "Taglia del Cane?";
  tagliaGrandeLabel_IT = "Grande (Più di 14 kg)";
  tagliaPiccolaLabel_IT = "Piccola";
  totaleLabel_IT = "Totale:"
  totaleParzialeLabel_IT = "Totale Parziale:";
  trattametoDati_IT = "Trattamento Dati Personali";
  verificaDispLabel_IT = "VERIFICA DISPONIBILITA'"
  viaggiaConCaniLabel_IT = "Viaggia con Cani?";
  viaggiaConCaniSiLabel_IT = "SI";
  welcome_emailNonValida_IT = "Indirizzo E-Mail non valido";
  welcome_fbLogin_IT = "Login con Facebook";
  welcome_inserireMail_IT = "Per favore inserire la propria e-mail";
  welcome_inserirePassword_IT = "Per favore inserire una password";
  welcome_passDimenticata_IT = "Hai dimenticato la password?";
  welcome_registrati_IT = "Registrati";
  ordineProntoLabel_IT = "Ordine pronto";
  ordineNonProntoLabel_IT = "Ordine non ancora pronto";
  ordineInGestioneLabel_IT = "Ordine in gestione";
  /* FINE LINGUA ITALIANA */

  /* LINGUA INGLESE */
  appUpdateLabel_EN = "App Update";
  ordineInGestioneLabel_EN = "Order in preparation";
  ordineProntoLabel_EN = "Your order is ready";
  ordineNonProntoLabel_EN = "The order isn't ready yet";
  prenotaAsportoLabel_EN = "The reservation is for today";
  accessoriExtraGazeboLabel_EN = "1 Direction Chair 1 Hammock 1 Table";
  sdraioSingolaLabel_EN = "Deck Chair";
  sdraioMultipleLabel_EN = "Deck Chairs";
  lettinoSingoloLabel_EN = "Sea bed";
  lettiniMultipliLabel_EN = "Sea Beds";
  aggiornaDatiLabel_EN = "Update Profile";
  aggiungiCarrelloLabel_EN = "Add to cart";
  alDateLabel_EN = "To:";
  alertAppObsoleta_EN = "Your App Version is out of date";
  alertAppObsoletaMess_EN = "Please update the app if you want to use it";
  alertAppInAggiornamento_EN = "Work In Progress";
  alertAppInAggiornamentoMess_EN = "You will be able to use the app soon.";
  alertDopoLeDodiciLabel_EN = "You can only book for dinner or for the next days";
  alertDopoLeUndiciLabel_EN = "After 11am you can only book for the next days";
  alertDopoLeVentiLabel_EN = "You can only book for the next days";
  alertError_EN = "Oops! Something bad just happened!";
  alertErroreDateLabel_EN = "The end date cannot be smaller than the start date";
  alertErroreLabel_EN = "Date Selection Error";
  alertErrorMessage_EN = "Try again :) If the error persists, logout and try again please";
  alertGiorniPassatiLabel_EN = "You can't go back in time :)";
  alertLetturaDati_EN = "Data reading...";
  alertMaxOmbrelloni_EN = "You can book max 5 umbrellas at once."
  alertOmbrNonDispLabel_EN = "The selected umbrella is no longer available, choose another one.";
  alertPagamentoInCorso_EN = "Payment...";
  alertPrenotaOmbrellone_EN = "Beach Umbrella Booking";
  alertPrenotaRistorante_EN = "Restaurant Booking";
  alertSalvataggioInCorso_EN = "Saving data...";
  alertTempoScaduto_EN = "Time expired for reserving: the cart has been emptied.";
  alertUtentePassErrati_EN = "Wrong E-mail or Password";
  alertUtentePassRiprovare_EN = "Check the entered data and try again.";
  annullaLabel_EN = "Cancel";
  annullaLabelTornaIndietro_EN = "Go Back";
  avantiLabel_EN = "PROCEED";
  barSpiaggiaLabel_EN = "Beach Orders";
  calcoloPrezzoLabel_EN = "Price calculation...";
  carrelloLabel_EN = "Go to cart";
  cartaCredito_aggiungiCartaLabel_EN = "Add Card";
  cartaCredito_aggiungiCartaPagamentoLabel_EN = "ADD OR MODIFY CARD";
  cartaCredito_cambiaCartaLabel_EN = "Change Card";
  cartaCredito_cartaLabel_EN = "Card";
  cartaCredito_consegnaLabel_EN = "Delivery: ";
  cartaCredito_errorMsgLabel_EN = "We are sorry, something went wrong.";
  cartaCredito_esitoPagamentoLabel_EN = "Payment Result";
  cartaCredito_modificaCartaLabel_EN = "Modify Credit Card";
  cartaCredito_modificaCartaPagamentoLabel_EN = "Modify payment card";
  cartaCredito_nessunaCartaLabel_EN = "No cards available";
  cartaCredito_numeroCartaLabel_EN = "Card Number";
  cartaCredito_ordineCompletatoLabel_EN = "ORDER COMPLETED";
  cartaCredito_ordineMsgLabel_EN = "The beach umbrella must be occupied by noon";
  cartaCredito_pagaConCartaLabel_EN = "Pay with Credit Card";
  cartaCredito_pagaOraLabel_EN = "PAY NOW";
  cartaCredito_rimuoviCartaLabel_EN = "Remove Card";
  cartaCredito_ritentaOrdineLabel_EN = "TRY AGAIN";
  cartaCredito_scadenzaCartaLabel_EN = "Expiry";
  cartaCredito_tornaHomeLabel_EN = "GO TO HOME";
  cartaCredito_totaleOrdineLabel_EN = "Total: ";
  checkoutCarrelloCartaCreditoLabel_EN = "Credit Card";
  checkoutCarrelloConcludiPagamentoLabel_EN = "Complete payment";
  checkoutCarrelloConsegnaOrdineBarLabel_EN = "Where to deliver your order?";
  checkoutCarrelloConsegnaOrdineMsgLabel_EN = "Please, enter where to deliver your order";
  checkoutCarrelloContantiLabel_EN = "Cash";
  checkoutCarrelloContinuaAcquistiLabel_EN = "Continue shopping";
  checkoutCarrelloDataLabel_EN = "Date:";
  checkoutCarrelloLabel_EN = "Cart";
  checkoutCarrelloMetodoPagamentoLabel_EN = "Select a payment method:";
  checkoutCarrelloNoPagamentoLabel_EN = "No Payment Needed";
  checkoutCarrelloNrLettiniLabel_EN = "Nr. of sea beds:";
  checkoutCarrelloNrSdraioLabel_EN = "Nr. of deck chairs:";
  checkoutCarrelloNumeroPostiRistoranteLabel_EN = "Nr of seats:";
  checkoutCarrelloOrarioLabel_EN = "Hour:";
  checkoutCarrelloOrdineBarLabel_EN = "Bar Order:";
  checkoutCarrelloOrdineOmbrelloniTimeoutLabel_EN = "Umbrellas Order";
  checkoutCarrelloPagaLabel_EN = "Pay";
  checkoutCarrelloPlaceHolderConsegnaLabel_EN = "Example: Umbrella A11";
  checkoutCarrelloPrenotatoLabel_EN = "Booked:";
  checkoutCarrelloQuandoLabel_EN = "When:";
  checkoutCarrelloRistoranteLabel_EN = "Restaurant booking:";
  checkoutCarrelloSvuotaCarrelloLabel_EN = "Empty cart";
  checkoutCarrelloTotaleLabel_EN = "Total:";
  checkoutCarrelloVuotoLabel_EN = "The cart is empty.";
  ciaoUserLabel_EN = "Hi";
  confermaLabel_EN = "Confirm";
  confermaOmbrellone_Label_EN = "Confirm your booking";
  confermaRistorante_Label_EN = "Confirm your booking";
  dalDateLabel_EN = "From:";
  dataLabel_EN = "Date:";
  dayNamesLabel_EN = "Sunday -, Monday -,  Tuesday-,  Wednesday-, Thursday -, Friday -, Saturday -";
  dogLabel_EN = "Dogs are welcome";
  fasciaOrariaLabel_EN = "Time Slot";
  finalitaTrattamento_EN = "Data Treatment Purpose";
  giornataLabel_EN = "All Day";
  giorniMultipliLabel_EN = "Multiple Days";
  giorniPrenotatiLabel_EN = "Days Booked:";
  hoDueCaniLabel_EN = "I Have two dogs";
  home_dataDifferente_EN = "Pick a different Date";
  home_dayNames_EN = "Sun -, Mon -, Tue -, Wed -, Thu -, Fri -, Sat -";
  home_disponibiliLabel_EN = "Available:";
  home_monthShortNames_EN = "Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec";
  home_postiLabel_EN = "Seats";
  home_prenotaOmbrellone_EN = "Book an Umbrella";
  home_prenotaOnline_EN = "Book Online";
  home_prenotaRistorante_EN = "Book the restaurant"
  home_ristoranteLabel_EN = "RESTAURANT";
  home_selezionaGiorno_EN = "When?";
  homeCena_EN = "Dinner";
  homeNumeroCoperti_EN = "How many seats?";
  homePranzo_EN = "Lunch";
  homePranzoOCena_EN = "Do you want to book Lunch or Dinner?";
  homePrenotaLabel_EN = "Book it";
  homeScegliNumeroCoperti_EN = "Please choose the nr. of seats";
  homeSelezionaOrario_EN = "Select the time";
  homeSelezionaOrarioMsg_EN = "Please, select the time";
  homeServizioBar_EN = "The service will be available as soon as possible.";
  infoAccessLabel_EN = "Accessibility";
  infoComunicazioniLabel_EN = "For any further info write to ";
  infoCusciniLabel_EN = "Cushions For";
  infoDocceLabel_EN = "Showers";
  infoFilaLabel_EN = "Row";
  infoLabel_EN = "Info";
  infonNoleggioLabel_EN = "Rental";
  infoParcheggioLabel_EN = "Parking";
  infoPrezziLabel_EN = "Prices";
  infoRistoranteLabel_EN = "Restaurant";
  infoServiziLabel_EN = "Our Services";
  inPreparazioneLabel_EN = "available soon";
  inserisciDatiLabel_EN = "Choose your umbrella:";
  insertName_EN = "Please insert your name";
  insertSurname_EN = "Please insert your surname";
  invalidPhone_EN = "Wrong phone number";
  isCarrelloIbridoLabel_EN = "YOU CAN'T BUY SERVICES, DRINKS AND UMBRELLAS AT THE SAME TIME, PLEASE PAY ONE CATEGORY AT A TIME";
  lettinoAggiuntivoLabel_EN = "Additional Sea Bed";
  lettinoLabel_EN = "SEA BED";
  listaCaniLabel_EN = "Dogs list:";
  listaOrdiniLabel_EN = "Orders";
  lunghMaxDodCar_EN = "Max length 12 characters";
  lunghMaxVentiCar_EN = "Max length 20 characters";
  lunghMaxVentiCinqueCar_EN = "Max length 25 characters";
  lunghMinDueCar_EN = "Min length 2 characters";
  lunghMinSeiCar_EN = "Min length 6 characters";
  lunghMinTreCar_EN = "Min length 3 characters";
  mattinaLabel_EN = "Morning";
  maxPostiPren_EN = "Available seats:";
  menuDelGiornoLabel_EN = "Today's special";
  menuPubblicoChiudiLabel_EN = "GO BACK";
  modificaProfiloLabel_EN = "Edit Profile";
  monthLongNames_EN = "January, February, March, April, May, June, July, August, September, October, November, December";
  nessunoLabel_EN = "NOTHING";
  nessunOrdineLabel_EN = "You have no orders";
  nienteSdraioLabel_EN = "There are no sea beds available.";
  nomeCaneDue_EN = "Dog Two:";
  nomeCaneUno_EN = "Dog One:";
  numeroCopertiLabel_EN = "Numbers of Seats:";
  numeroLettiniLabel_EN = "Number of beds:";
  numeroSdraioLabel_EN = "Number of deck chairs:";
  numeroSeduteLabel_EN = "Nr. of seats";
  numeroServiziAggLabel_EN = "Additional services: ";
  obbligoDueSdraioLabel_EN = "You must book at least 2 sea beds.";
  obbligoUnaSdraioLabel_EN = "You must book at least 1 sea bed.";
  ombrelloneLabel_EN = "Beach Umbrella";
  onlyLetters_EN = "Enter letters only";
  onlyNumbers_EN = "Enter numbers only";
  ordineEffettuatoLabel_EN = "Shopping list ";
  ordineNrLabel_EN = "Booking Nr.";
  pomeriggioLabel_EN = "Afternoon";
  posizioneLabel_EN = "Position:";
  postiDisp_EN = "There are no seats available.";
  pranzoCena_EN = "Lunch or Dinner?";
  prenotaPostoLabel_EN = "Book an Umbrella";
  prenotaTabBar_EN = "Book";
  prenotazioneLabel_EN = "Lunch or Dinner?"
  proseguiGuestLabel_EN = "Continue as a guest";
  resetPass_cambiaPassword_EN = "Change Password";
  resetPass_confermaCambioLabel_EN = "You will get a confirmation email";
  resetPass_diversePassword_EN = "The passwords are different";
  resetPass_emailNonValida_EN = "Invalid E-mail address";
  resetPass_inserireMail_EN = "Please insert your E-mail address";
  resetPass_inserirePassword_EN = "Please, choose a password";
  resetPass_inserisciDatiLabel_EN = "Enter your data to reset the password:";
  resetPass_modificaLabel_EN = "Password Reset";
  resetPass_msgConfermaLabel_EN = "Click on the confirmation email to log in with the new password ";
  resetPass_reinserirePassword_EN = "Please, re-enter the password";
  resetPass_riscriviLabel_EN = "Re-Enter";
  resetPass_spamLabel_EN = "(Check junk mail)";
  riepilogoLabel_EN = "Order summary";
  ristorante_confermaLabel_EN = "Book it";
  ristorante_disponibiliLabel_EN = "Available:";
  ristorante_postiLabel_EN = "Seats";
  ristoranteLabel_EN = "Restaurant";
  scegliDataLabel_EN = "Please, pick a date";
  scegliOmbrelloLabel_EN = "Choose an Umbrella";
  sdraioAggiuntivaLabel_EN = "Additional Deck Chair";
  sdraioAggLabel_EN = "DECK CHAIR";
  sdraioLabel_EN = "Deck chairs:";
  seduteAggLabel_EN = "ADDITIONAL SEA BEDS";
  signup_account_EN = "Account Creation";
  signup_cognome_EN = "Surname";
  signup_completa_messDati_EN = "To proceed enter all the data requested."
  signup_completaRegistrazione_EN = "Complete Registration";
  signup_emailNonValida_EN = "Invalid E-mail address";
  signup_inserireMail_EN = "Please insert your E-mail address";
  signup_inserirePassword_EN = "Please, choose a password";
  signup_inserireTelefono_EN = "Please insert your Phone number";
  signup_nome_EN = "Name";
  signup_presoVisioneInformativa_EN = "I have read the information on personal <br> data processing";
  signup_presoVisioneTrattamento_EN = "I have read the information on the Purpose <br> of the data processing";
  signup_telefono_EN = "Phone";
  tagliaCaneLabel_EN = "Dog Size?";
  tagliaGrandeLabel_EN = "Large (More than 14 kg)";
  tagliaPiccolaLabel_EN = "Small";
  totaleLabel_EN = "Total:"
  totaleParzialeLabel_EN = "Subtotal:";
  trattametoDati_EN = "Personal Data Processing";
  verificaDispLabel_EN = "CHECK AVAILABILITY"
  viaggiaConCaniLabel_EN = "Do you have dogs?";
  viaggiaConCaniSiLabel_EN = "YES";
  welcome_emailNonValida_EN = "Invalid E-mail address";
  welcome_fbLogin_EN = "Facebook Login";
  welcome_inserireMail_EN = "Please insert your E-mail address";
  welcome_inserirePassword_EN = "Please, choose a password";
  welcome_passDimenticata_EN = "Forgot Password?";
  welcome_registrati_EN = "Signup";

  /* FINE LINGUA INGLESE */

  /* LINGUA FRANCESE */
  appUpdateLabel_FR = "Update App";
  ordineInGestioneLabel_FR = "Commande en préparation";
  ordineProntoLabel_FR = "Votre commande est prête";
  ordineNonProntoLabel_FR = "Votre commande n'est pas encore prête";
  prenotaAsportoLabel_FR = "la réservation est pour aujourd'hui";
  accessoriExtraGazeboLabel_FR = "1 Chaise de cinéma 1 Hamac 1 Table";
  sdraioSingolaLabel_FR = "Chaise longues";
  sdraioMultipleLabel_FR = "Chaises longues";
  lettinoSingoloLabel_FR = "Lit de plage";
  lettiniMultipliLabel_FR = "Lits de plage";
  aggiornaDatiLabel_FR = "Update le profil";
  aggiungiCarrelloLabel_FR = "Ajouter au panier";
  alDateLabel_FR = "Au";
  alertAppObsoleta_FR = "La version de votre application est obsolète";
  alertAppObsoletaMess_FR = "Veuillez mettre à jour l'application si vous souhaitez l'utiliser";
  alertAppInAggiornamento_FR = "Application en maintenance";
  alertAppInAggiornamentoMess_FR = "Nous nous excusons pour le dérangement";
  alertDopoLeDodiciLabel_FR = "Vous ne pouvez réserver que pour le dîner ou pour les prochains jours";
  alertDopoLeUndiciLabel_FR = "Après 11h, vous ne pouvez réserver que pour les prochains jours";
  alertDopoLeVentiLabel_FR = "Vous ne pouvez réserver que pour les prochains jours";
  alertError_FR = "Oups! Quelque chose d'horrible est arrivé!";
  alertErroreDateLabel_FR = "La date de fin ne peut pas être inférieure à la date de début";
  alertErroreLabel_FR = "Erreur de sélection de date";
  alertErrorMessage_FR = "Try again :) If the error persists, logout and try again please";
  alertGiorniPassatiLabel_FR = "Réessayez :) Si l'erreur persiste, déconnectez-vous et réessayez s'il vous plaît";
  alertLetturaDati_FR = "Lecture des données ...";
  alertMaxOmbrelloni_FR = "Vous pouvez réserver jusqu'à 5 parasols à la fois."
  alertOmbrNonDispLabel_FR = "Le parasol sélectionné n'est plus disponible, choisissez-en un autre.";
  alertPagamentoInCorso_FR = "Paiement...";
  alertPrenotaOmbrellone_FR = "Réservation de parasol ";
  alertPrenotaRistorante_FR = "Réservation de restaurant";
  alertSalvataggioInCorso_FR = "Sauvegarde des données...";
  alertTempoScaduto_FR = "Délai de réservation écoulé: le panier est vidé.";
  alertUtentePassErrati_FR = "E-mail ou mot de passe incorrect";
  alertUtentePassRiprovare_FR = "Vérifiez les données saisies et réessayez.";
  annullaLabel_FR = "Annuler";
  avantiLabel_FR = "PROCÉDER";
  barSpiaggiaLabel_FR = "Ordres de plage";
  calcoloPrezzoLabel_FR = "Calcul du prix ...";
  carrelloLabel_FR = "Aller au panier";
  cartaCredito_aggiungiCartaLabel_FR = "Ajouter une carte";
  cartaCredito_aggiungiCartaPagamentoLabel_FR = "AJOUTER OU MODIFIER UNE CARTE";
  cartaCredito_cambiaCartaLabel_FR = "Changer la carte";
  cartaCredito_cartaLabel_FR = "Carte";
  cartaCredito_consegnaLabel_FR = "Livraison: ";
  cartaCredito_errorMsgLabel_FR = "Nous sommes désolés, quelque chose n'a pas fonctionné.";
  cartaCredito_esitoPagamentoLabel_FR = "Résultat du paiement";
  cartaCredito_modificaCartaLabel_FR = "Modifier la carte de crédit";
  cartaCredito_modificaCartaPagamentoLabel_FR = "Modifier la carte de paiement";
  cartaCredito_nessunaCartaLabel_FR = "Aucune carte disponible";
  cartaCredito_numeroCartaLabel_FR = "Numéro de carte";
  cartaCredito_ordineCompletatoLabel_FR = "COMMANDE TERMINÉE";
  cartaCredito_ordineMsgLabel_FR = "Le parasol doit être occupé à midi";
  cartaCredito_pagaConCartaLabel_FR = "Payez avec une carte de crédit";
  cartaCredito_pagaOraLabel_FR = "PAYEZ MAINTENANT";
  cartaCredito_rimuoviCartaLabel_FR = "Retirer la carte";
  cartaCredito_ritentaOrdineLabel_FR = "RÉESSAYER";
  cartaCredito_scadenzaCartaLabel_FR = "Expiration";
  cartaCredito_tornaHomeLabel_FR = "PAGE PRINCIPALE";
  cartaCredito_totaleOrdineLabel_FR = "Total: ";
  checkoutCarrelloCartaCreditoLabel_FR = "Carte de crédit";
  checkoutCarrelloConcludiPagamentoLabel_FR = "Terminez le paiement";
  checkoutCarrelloConsegnaOrdineBarLabel_FR = "Où livrer votre commande?";
  checkoutCarrelloConsegnaOrdineMsgLabel_FR = "Veuillez indiquer où livrer votre commande";
  checkoutCarrelloContantiLabel_FR = "Cash";
  checkoutCarrelloContinuaAcquistiLabel_FR = "Continuer vos achats";
  checkoutCarrelloDataLabel_FR = "Date:";
  checkoutCarrelloLabel_FR = "Panier";
  checkoutCarrelloMetodoPagamentoLabel_FR = "Méthode de paiement?";
  checkoutCarrelloNoPagamentoLabel_FR = "Aucun paiement requis";
  checkoutCarrelloNrLettiniLabel_FR = "Lits de plage:";
  checkoutCarrelloNrSdraioLabel_FR = "Chaises longues:";
  checkoutCarrelloNumeroPostiRistoranteLabel_FR = "Nombre de places";
  checkoutCarrelloOrarioLabel_FR = "Heure:";
  checkoutCarrelloOrdineBarLabel_FR = "Bar:";
  checkoutCarrelloOrdineOmbrelloniTimeoutLabel_FR = "Commande des Parasols";
  checkoutCarrelloPagaLabel_FR = "Payer";
  checkoutCarrelloPlaceHolderConsegnaLabel_FR = "Exemple: Parasol A11";
  checkoutCarrelloPrenotatoLabel_FR = "Réservé:";
  checkoutCarrelloQuandoLabel_FR = "Quand:";
  checkoutCarrelloRistoranteLabel_FR = "Réservation au restaurant:";
  checkoutCarrelloSvuotaCarrelloLabel_FR = "Videz le panier";
  checkoutCarrelloTotaleLabel_FR = "Total:";
  checkoutCarrelloVuotoLabel_FR = "Le panier est vide.";
  ciaoUserLabel_FR = "Salut";
  confermaLabel_FR = "Confirmer";
  confermaOmbrellone_Label_FR = "Confirmez votre réservation";
  confermaRistorante_Label_FR = "Confirmez votre réservation";
  dalDateLabel_FR = "Du";
  dataLabel_FR = "Date:";
  dayNamesLabel_FR = "Dimanche -, Lundi -,  Mardi-,  Mercredi-, Jeudi -, Vendredi -, Samedi -";
  dogLabel_FR = "Les chiens sont les bienvenus";
  fasciaOrariaLabel_FR = "Créneau horaire";
  finalitaTrattamento_FR = "Objectif du traitement des données";
  giornataLabel_FR = "Toute la journée";
  giorniMultipliLabel_FR = "Plusieurs jours";
  giorniPrenotatiLabel_FR = "Jours réservés:";
  hoDueCaniLabel_FR = "J'ai deux chiens";
  home_dataDifferente_FR = "Choisissez une autre date";
  home_dayNames_FR = "Dim -, Lun -, Mar -, Mer -, Jeu -, Ven -, Sam -";
  home_disponibiliLabel_FR = "Available:";
  home_monthShortNames_FR = "Jan, Fév, Mar, Avr, Mai, Jun, Jul, Aou, Sep, Oct, Nov, Déc";
  home_postiLabel_FR = "Sièges";
  home_prenotaOmbrellone_FR = "Réservez un parasol";
  home_prenotaOnline_FR = "Réservation online";
  home_prenotaRistorante_FR = "Réservez le restaurant"
  home_ristoranteLabel_FR = "RESTAURANT";
  home_selezionaGiorno_FR = "Quand?";
  homeCena_FR = "Dîner";
  homeNumeroCoperti_FR = "Combien de sièges?";
  homePranzo_FR = "Le déjeuner";
  homePranzoOCena_FR = "Réserver pour le déjeuner ou le dîner?";
  homePrenotaLabel_FR = "Réserver";
  homeScegliNumeroCoperti_FR = "Veuillez choisir le nr. de sièges";
  homeSelezionaOrario_FR = "Sélectionnez l'heure";
  homeSelezionaOrarioMsg_FR = "Veuillez sélectionner l'heure";
  homeServizioBar_FR = "Le service sera disponible dès que possible. le temps";
  infoAccessLabel_FR = "Accessibilité";
  infoComunicazioniLabel_FR = "Pour toute information complémentaire, écrivez à ";
  infoCusciniLabel_FR = "Coussins pour";
  infoDocceLabel_FR = "Douches";
  infoFilaLabel_FR = "Rangée";
  infoLabel_FR = "Info";
  infonNoleggioLabel_FR = "Location";
  infoParcheggioLabel_FR = "Parking";
  infoPrezziLabel_FR = "Prix";
  infoRistoranteLabel_FR = "Restaurant";
  infoServiziLabel_FR = "Nos services";
  inPreparazioneLabel_FR = "Bientôt disponible";
  inserisciDatiLabel_FR = "Choisissez votre parasol";
  insertName_FR = "Veuillez insérer votre nom";
  insertSurname_FR = "Veuillez insérer votre nom de famille";
  invalidPhone_FR = "Mauvais numéro de téléphone";
  isCarrelloIbridoLabel_FR = "VOUS NE POUVEZ PAS ACHETER DES SERVICES, DES BOISSONS ET DES PARAPLUIES EN MÊME TEMPS, VEUILLEZ PAYER UNE CATÉGORIE À LA FOIS";
  lettinoAggiuntivoLabel_FR = "Lits de plage supplémentaires";
  lettinoLabel_FR = "LIT DE PLAGE";
  listaCaniLabel_FR = "Liste des chiens:";
  listaOrdiniLabel_FR = "Commande";
  lunghMaxDodCar_FR = "Longueur max 12 caractères";
  lunghMaxVentiCar_FR = "Longueur max 20 caractères";
  lunghMaxVentiCinqueCar_FR = "Longueur max 25 caractères";
  lunghMinDueCar_FR = "Longueur min 2 caractères";
  lunghMinSeiCar_FR = "Longueur min 6 caractères";
  lunghMinTreCar_FR = "Longueur min 3 caractères";
  mattinaLabel_FR = "Matin";
  maxPostiPren_FR = "Places libres:";
  menuDelGiornoLabel_FR = "Le plat du jour";
  menuPubblicoChiudiLabel_FR = "RETOURNER";
  modificaProfiloLabel_FR = "Editer le profil";
  monthLongNames_FR = "Janvier, Février, Mars, Avril, Mai, Juin, Juillet, Août, Septembre, Octobre, Novembre, Décembre";
  nessunoLabel_FR = "RIEN";
  nessunOrdineLabel_FR = "Vous n'avez aucune commande";
  nienteSdraioLabel_FR = "Il n'y a pas de lits de plage disponibles.";
  nomeCaneDue_FR = "Chien Deux:";
  nomeCaneUno_FR = "Dog Un:";
  numeroCopertiLabel_FR = "Nombre de sièges:";
  numeroLettiniLabel_FR = "Nombre de lits de plage:";
  numeroSdraioLabel_FR = "Nombre de chaises longues:";
  numeroSeduteLabel_FR = "Nr. de sièges";
  numeroServiziAggLabel_FR = "Services supplémentaires:";
  obbligoDueSdraioLabel_FR = "Vous devez réserver au moins 2 lits de plage";
  obbligoUnaSdraioLabel_FR = "Vous devez réserver au moins 1 lit de plage";
  ombrelloneLabel_FR = "Parasol";
  onlyLetters_FR = "Lettres seulement";
  onlyNumbers_FR = "Chiffres uniquement";
  ordineEffettuatoLabel_FR = "Shopping liste ";
  ordineNrLabel_FR = "Réservation Nr.";
  pomeriggioLabel_FR = "Après midi";
  posizioneLabel_FR = "Position:";
  postiDisp_FR = "Il n'y a pas de places disponibles.";
  pranzoCena_FR = "Déjeuner ou dîner?";
  prenotaPostoLabel_FR = "Réservez un parasol";
  prenotaTabBar_FR = "Réserver";
  prenotazioneLabel_FR = "Déjeuner ou dîner?";
  proseguiGuestLabel_FR = "Continuer en tant que visiteur";
  resetPass_cambiaPassword_FR = "Changer le mot de passe";
  resetPass_confermaCambioLabel_FR = "Vous recevrez un email de confirmation";
  resetPass_diversePassword_FR = "Les mots de passe sont différents";
  resetPass_emailNonValida_FR = "Adresse e-mail invalide";
  resetPass_inserireMail_FR = "Veuillez insérer votre adresse e-mail";
  resetPass_inserirePassword_FR = "Veuillez choisir un mot de passe";
  resetPass_inserisciDatiLabel_FR = "Entrez vos données pour réinitialiser le mot de passe:";
  resetPass_modificaLabel_FR = "Réinitialisation du mot de passe";
  resetPass_msgConfermaLabel_FR = "Cliquez sur l'e-mail de confirmation pour vous connecter avec le nouveau mot de passe ";
  resetPass_reinserirePassword_FR = "Veuillez retaper le mot de passe";
  resetPass_riscriviLabel_FR = "Retaper";
  resetPass_spamLabel_FR = "(Vérifiez le courrier indésirable)";
  riepilogoLabel_FR = "Récapitulatif de la commande";
  ristorante_confermaLabel_FR = "Réserver";
  ristorante_disponibiliLabel_FR = "Disponible:";
  ristorante_postiLabel_FR = "Places";
  ristoranteLabel_FR = "Restaurant";
  scegliDataLabel_FR = "Veuillez choisir une date";
  scegliOmbrelloLabel_FR = "Choisissez un parasol";
  sdraioAggiuntivaLabel_FR = "Chaise longue supplémentaire";
  sdraioAggLabel_FR = "CHAISE LONGUE";
  sdraioLabel_FR = "Chaises longues:";
  seduteAggLabel_FR = "LITS DE MER SUPPLÉMENTAIRES";
  signup_account_FR = "Enregistrement";
  signup_cognome_FR = "Nom de famille";
  signup_completa_messDati_FR = "Pour continuer, entrez toutes les données demandées."
  signup_completaRegistrazione_FR = "Terminer l'inscription";
  signup_emailNonValida_FR = "Adresse e-mail invalide";
  signup_inserireMail_FR = "Veuillez insérer votre adresse e-mail";
  signup_inserirePassword_FR = "Veuillez choisir un mot de passe";
  signup_inserireTelefono_FR = "Veuillez insérer votre numéro de téléphone";
  signup_nome_FR = "Nom";
  signup_presoVisioneInformativa_FR = "J'ai lu les informations sur le traitement <br> des données personnelles";
  signup_presoVisioneTrattamento_FR = "J'ai lu les informations sur la finalité <br> du traitement des données";
  signup_telefono_FR = "Téléphone";
  tagliaCaneLabel_FR = "Taille du chien?";
  tagliaGrandeLabel_FR = "Grand (plus de 14 kg)";
  tagliaPiccolaLabel_FR = "Petit";
  totaleLabel_FR = "Total:"
  totaleParzialeLabel_FR = "Subtotal:";
  trattametoDati_FR = "Traitement des données personnelles";
  verificaDispLabel_FR = "VOIR LES DISPONIBILITÉS"
  viaggiaConCaniLabel_FR = "Avez-vous des chiens?";
  viaggiaConCaniSiLabel_FR = "OUI";
  welcome_emailNonValida_FR = "Adresse e-mail invalide";
  welcome_fbLogin_FR = "Facebook Login";
  welcome_inserireMail_FR = "Veuillez insérer votre adresse e-mail";
  welcome_inserirePassword_FR = "Veuillez choisir un mot de passe";
  welcome_passDimenticata_FR = "Mot de passe oublié?";
  welcome_registrati_FR = "S'inscrire";


  /* FINE LINGUA FRANCESE */

  /* LINGUA OLANDESE */
  appUpdateLabel_NL = "Update App";
  ordineInGestioneLabel_NL = "Commande en préparation";
  ordineProntoLabel_NL = "Votre commande est prête";
  ordineNonProntoLabel_NL = "Votre commande n'est pas encore prête";
  prenotaAsportoLabel_NL = "Recevoir directement au parapluie";
  accessoriExtraGazeboLabel_NL = "1 Chaise de cinéma 1 Hamac 1 Table";
  sdraioSingolaLabel_NL = "Deck Chair";
  sdraioMultipleLabel_NL = "Deck Chairs";
  lettinoSingoloLabel_NL = "Sea bed";
  lettiniMultipliLabel_NL = "Sea Beds";
  aggiornaDatiLabel_NL = "Update Profile";
  aggiungiCarrelloLabel_NL = "Add to cart";
  alDateLabel_NL = "To:";
  alertAppObsoleta_NL = "Your App Version is out of date";
  alertAppObsoletaMess_NL = "Please update the app if you want to use it";
  alertAppInAggiornamento_NL = "Application en maintenance";
  alertAppInAggiornamentoMess_NL = "Nous nous excusons pour le dérangement";
  alertDopoLeDodiciLabel_NL = "You can only book for dinner or for the next days";
  alertDopoLeUndiciLabel_NL = "After 11am you can only book for the next days";
  alertDopoLeVentiLabel_NL = "You can only book for the next days";
  alertError_NL = "Oops! Something bad just happened!";
  alertErroreDateLabel_NL = "The end date cannot be smaller than the start date";
  alertErroreLabel_NL = "Date Selection Error";
  alertErrorMessage_NL = "Try again :) If the error persists, logout and try again please";
  alertGiorniPassatiLabel_NL = "You can't go back in time :)";
  alertLetturaDati_NL = "Data reading...";
  alertMaxOmbrelloni_NL = "You can book max 5 umbrellas at once."
  alertOmbrNonDispLabel_NL = "The selected umbrella is no longer available, choose another one.";
  alertPagamentoInCorso_NL = "Payment...";
  alertPrenotaOmbrellone_NL = "Beach Umbrella Booking";
  alertPrenotaRistorante_NL = "Restaurant Booking";
  alertSalvataggioInCorso_NL = "Saving data...";
  alertTempoScaduto_NL = "Time expired for reserving: the cart has been emptied.";
  alertUtentePassErrati_NL = "Wrong E-mail or Password";
  alertUtentePassRiprovare_NL = "Check the entered data and try again.";
  annullaLabel_NL = "Cancel";
  avantiLabel_NL = "PROCEED";
  barSpiaggiaLabel_NL = "Beach Orders";
  calcoloPrezzoLabel_NL = "Price calculation...";
  carrelloLabel_NL = "Go to cart";
  cartaCredito_aggiungiCartaLabel_NL = "Add Card";
  cartaCredito_aggiungiCartaPagamentoLabel_NL = "ADD OR MODIFY CARD";
  cartaCredito_cambiaCartaLabel_NL = "Change Card";
  cartaCredito_cartaLabel_NL = "Card";
  cartaCredito_consegnaLabel_NL = "Delivery: ";
  cartaCredito_errorMsgLabel_NL = "We are sorry, something went wrong.";
  cartaCredito_esitoPagamentoLabel_NL = "Payment Result";
  cartaCredito_modificaCartaLabel_NL = "Modify Credit Card";
  cartaCredito_modificaCartaPagamentoLabel_NL = "Modify payment card";
  cartaCredito_nessunaCartaLabel_NL = "No cards available";
  cartaCredito_numeroCartaLabel_NL = "Card Number";
  cartaCredito_ordineCompletatoLabel_NL = "ORDER COMPLETED";
  cartaCredito_ordineMsgLabel_NL = "The beach umbrella must be occupied by noon";
  cartaCredito_pagaConCartaLabel_NL = "Pay with Credit Card";
  cartaCredito_pagaOraLabel_NL = "PAY NOW";
  cartaCredito_rimuoviCartaLabel_NL = "Remove Card";
  cartaCredito_ritentaOrdineLabel_NL = "TRY AGAIN";
  cartaCredito_scadenzaCartaLabel_NL = "Expiry";
  cartaCredito_tornaHomeLabel_NL = "GO TO HOME";
  cartaCredito_totaleOrdineLabel_NL = "Total: ";
  checkoutCarrelloCartaCreditoLabel_NL = "Credit Card";
  checkoutCarrelloConcludiPagamentoLabel_NL = "Complete payment";
  checkoutCarrelloConsegnaOrdineBarLabel_NL = "Where to deliver your order?";
  checkoutCarrelloConsegnaOrdineMsgLabel_NL = "Please, enter where to deliver your order";
  checkoutCarrelloContantiLabel_NL = "Cash";
  checkoutCarrelloContinuaAcquistiLabel_NL = "Continue shopping";
  checkoutCarrelloDataLabel_NL = "Date:";
  checkoutCarrelloLabel_NL = "Cart";
  checkoutCarrelloMetodoPagamentoLabel_NL = "Select a payment method:";
  checkoutCarrelloNoPagamentoLabel_NL = "No Payment Needed";
  checkoutCarrelloNrLettiniLabel_NL = "Nr. of sea beds:";
  checkoutCarrelloNrSdraioLabel_NL = "Nr. of deck chairs:";
  checkoutCarrelloNumeroPostiRistoranteLabel_NL = "Nr of seats:";
  checkoutCarrelloOrarioLabel_NL = "Hour:";
  checkoutCarrelloOrdineBarLabel_NL = "Bar Order:";
  checkoutCarrelloOrdineOmbrelloniTimeoutLabel_NL = "Umbrellas Order";
  checkoutCarrelloPagaLabel_NL = "Pay";
  checkoutCarrelloPlaceHolderConsegnaLabel_NL = "Example: Umbrella A11";
  checkoutCarrelloPrenotatoLabel_NL = "Booked:";
  checkoutCarrelloQuandoLabel_NL = "When:";
  checkoutCarrelloRistoranteLabel_NL = "Restaurant booking:";
  checkoutCarrelloSvuotaCarrelloLabel_NL = "Empty cart";
  checkoutCarrelloTotaleLabel_NL = "Total:";
  checkoutCarrelloVuotoLabel_NL = "The cart is empty.";
  ciaoUserLabel_NL = "Hi";
  confermaLabel_NL = "Confirm";
  confermaOmbrellone_Label_NL = "Confirm your booking";
  confermaRistorante_Label_NL = "Confirm your booking";
  dalDateLabel_NL = "From:";
  dataLabel_NL = "Date:";
  dayNamesLabel_NL = "Sunday -, Monday -,  Tuesday-,  Wednesday-, Thursday -, Friday -, Saturday -";
  dogLabel_NL = "Dogs are welcome";
  fasciaOrariaLabel_NL = "Time Slot";
  finalitaTrattamento_NL = "Data Treatment Purpose";
  giornataLabel_NL = "All Day";
  giorniMultipliLabel_NL = "Multiple Days";
  giorniPrenotatiLabel_NL = "Days Booked:";
  hoDueCaniLabel_NL = "I Have two dogs";
  home_dataDifferente_NL = "Pick a different Date";
  home_dayNames_NL = "Sun -, Mon -, Tue -, Wed -, Thu -, Fri -, Sat -";
  home_disponibiliLabel_NL = "Available:";
  home_monthShortNames_NL = "Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec";
  home_postiLabel_NL = "Seats";
  home_prenotaOmbrellone_NL = "Book an Umbrella";
  home_prenotaOnline_NL = "Book Online";
  home_prenotaRistorante_NL = "Book the restaurant"
  home_ristoranteLabel_NL = "RESTAURANT";
  home_selezionaGiorno_NL = "When?";
  homeCena_NL = "Dinner";
  homeNumeroCoperti_NL = "How many seats?";
  homePranzo_NL = "Lunch";
  homePranzoOCena_NL = "Do you want to book Lunch or Dinner?";
  homePrenotaLabel_NL = "Book it";
  homeScegliNumeroCoperti_NL = "Please choose the nr. of seats";
  homeSelezionaOrario_NL = "Select the time";
  homeSelezionaOrarioMsg_NL = "Please, select the time";
  homeServizioBar_NL = "The service will be available as soon as possible.";
  infoAccessLabel_NL = "Accessibility";
  infoComunicazioniLabel_NL = "For any further info write to ";
  infoCusciniLabel_NL = "Cushions For";
  infoDocceLabel_NL = "Showers";
  infoFilaLabel_NL = "Row";
  infoLabel_NL = "Info";
  infonNoleggioLabel_NL = "Rental";
  infoParcheggioLabel_NL = "Parking";
  infoPrezziLabel_NL = "Prices";
  infoRistoranteLabel_NL = "Restaurant";
  infoServiziLabel_NL = "Our Services";
  inPreparazioneLabel_NL = "available soon";
  inserisciDatiLabel_NL = "Choose your umbrella:";
  insertName_NL = "Please insert your name";
  insertSurname_NL = "Please insert your surname";
  invalidPhone_NL = "Wrong phone number";
  isCarrelloIbridoLabel_NL = "YOU CAN'T BUY SERVICES, DRINKS AND UMBRELLAS AT THE SAME TIME, PLEASE PAY ONE CATEGORY AT A TIME";
  lettinoAggiuntivoLabel_NL = "Additional Sea Bed";
  lettinoLabel_NL = "SEA BED";
  listaCaniLabel_NL = "Dogs list:";
  listaOrdiniLabel_NL = "Orders";
  lunghMaxDodCar_NL = "Max length 12 characters";
  lunghMaxVentiCar_NL = "Max length 20 characters";
  lunghMaxVentiCinqueCar_NL = "Max length 25 characters";
  lunghMinDueCar_NL = "Min length 2 characters";
  lunghMinSeiCar_NL = "Min length 6 characters";
  lunghMinTreCar_NL = "Min length 3 characters";
  mattinaLabel_NL = "Morning";
  maxPostiPren_NL = "Available seats:";
  menuDelGiornoLabel_NL = "Today's special";
  menuPubblicoChiudiLabel_NL = "GO BACK";
  modificaProfiloLabel_NL = "Edit Profile";
  monthLongNames_NL = "January, February, March, April, May, June, July, August, September, October, November, December";
  nessunoLabel_NL = "NOTHING";
  nessunOrdineLabel_NL = "You have no orders";
  nienteSdraioLabel_NL = "There are no sea beds available.";
  nomeCaneDue_NL = "Dog Two:";
  nomeCaneUno_NL = "Dog One:";
  numeroCopertiLabel_NL = "Numbers of Seats:";
  numeroLettiniLabel_NL = "Number of beds:";
  numeroSdraioLabel_NL = "Number of deck chairs:";
  numeroSeduteLabel_NL = "Numbers of seats";
  numeroServiziAggLabel_NL = "Additional services: ";
  obbligoDueSdraioLabel_NL = "You must book at least 2 sea beds.";
  obbligoUnaSdraioLabel_NL = "You must book at least 1 sea bed.";
  ombrelloneLabel_NL = "Beach Umbrella";
  onlyLetters_NL = "Enter letters only";
  onlyNumbers_NL = "Enter numbers only";
  ordineEffettuatoLabel_NL = "Shopping list ";
  ordineNrLabel_NL = "Booking Nr.";
  pomeriggioLabel_NL = "Afternoon";
  posizioneLabel_NL = "Position:";
  postiDisp_NL = "There are no seats available.";
  pranzoCena_NL = "Lunch or Dinner?";
  prenotaPostoLabel_NL = "Book an Umbrella";
  prenotaTabBar_NL = "Book";
  prenotazioneLabel_NL = "Lunch or Dinner?"
  proseguiGuestLabel_NL = "Continue as a guest";
  resetPass_cambiaPassword_NL = "Change Password";
  resetPass_confermaCambioLabel_NL = "You will get a confirmation email";
  resetPass_diversePassword_NL = "The passwords are different";
  resetPass_emailNonValida_NL = "Invalid E-mail address";
  resetPass_inserireMail_NL = "Please insert your E-mail address";
  resetPass_inserirePassword_NL = "Please, choose a password";
  resetPass_inserisciDatiLabel_NL = "Enter your data to reset the password:";
  resetPass_modificaLabel_NL = "Password Reset";
  resetPass_msgConfermaLabel_NL = "Click on the confirmation email to log in with the new password ";
  resetPass_reinserirePassword_NL = "Please, re-enter the password";
  resetPass_riscriviLabel_NL = "Re-Enter";
  resetPass_spamLabel_NL = "(Check junk mail)";
  riepilogoLabel_NL = "Order summary";
  ristorante_confermaLabel_NL = "Book it";
  ristorante_disponibiliLabel_NL = "Available:";
  ristorante_postiLabel_NL = "Seats";
  ristoranteLabel_NL = "Restaurant";
  scegliDataLabel_NL = "Please, pick a date";
  scegliOmbrelloLabel_NL = "Choose an Umbrella";
  sdraioAggiuntivaLabel_NL = "Additional Deck Chair";
  sdraioAggLabel_NL = "DECK CHAIR";
  sdraioLabel_NL = "Deck chairs:";
  seduteAggLabel_NL = "ADDITIONAL SEA BEDS";
  signup_account_NL = "Account Creation";
  signup_cognome_NL = "Surname";
  signup_completa_messDati_NL = "To proceed enter all the data requested."
  signup_completaRegistrazione_NL = "Complete Registration";
  signup_emailNonValida_NL = "Invalid E-mail address";
  signup_inserireMail_NL = "Please insert your E-mail address";
  signup_inserirePassword_NL = "Please, choose a password";
  signup_inserireTelefono_NL = "Please insert your Phone number";
  signup_nome_NL = "Name";
  signup_presoVisioneInformativa_NL = "I have read the information on personal <br> data processing";
  signup_presoVisioneTrattamento_NL = "I have read the information on the Purpose <br> of the data processing";
  signup_telefono_NL = "Phone";
  tagliaCaneLabel_NL = "Dog Size?";
  tagliaGrandeLabel_NL = "Large (More than 14 kg)";
  tagliaPiccolaLabel_NL = "Small";
  totaleLabel_NL = "Total:"
  totaleParzialeLabel_NL = "Subtotal:";
  trattametoDati_NL = "Personal Data Processing";
  verificaDispLabel_NL = "CHECK AVAILABILITY"
  viaggiaConCaniLabel_NL = "Do you have dogs?";
  viaggiaConCaniSiLabel_NL = "YES";
  welcome_emailNonValida_NL = "Invalid E-mail address";
  welcome_fbLogin_NL = "Facebook Login";
  welcome_inserireMail_NL = "Please insert your E-mail address";
  welcome_inserirePassword_NL = "Please, choose a password";
  welcome_passDimenticata_NL = "Forgot Password?";
  welcome_registrati_NL = "Signup";


  /* FINE LINGUA OLANDESE */

  /* Menu Profilo */
  profilo_personale_IT = "Profilo Personale";
  profilo_personale_EN = "Account";
  profilo_personale_FR = "Account";
  trattamento_dati_personali_EN = "Personal Data Processing";
  trattamento_dati_personali_IT = "Trattamento Dati Personali";
  trattamento_dati_personali_FR = "Traitement des données personnelles";
  finalita_dati_personali_EN = "Purpose of data processing";
  finalita_dati_personali_IT = "Finalità Trattamento Dati";
  finalita_dati_personali_FR = "Finalité du traitement des données";
  elimina_account_IT = "Elimina Account";
  elimina_account_EN = "Delete Account";
  elimina_account_FR = "Supprimer le compte";
  /* Fine Menu Profilo */


  constructor(private storage: StorageService) {
    this.storage.get('locale_lang').then((val) => {
      // console.log("sono dentro lang" + val);
      //  console.log("Stato Token Dentro App Component: " + val);
      if (val != undefined && val != null && val != '') {
        this.languageSelected = val;
        this.controllaFlag();
      }
      else {
        this.storage.set('locale_lang', 'IT').then((val) => {
          this.languageSelected = 'IT';
          this.controllaFlag();
        });
      }
    });
  }

  controllaFlag() {
    if (this.languageSelected == 'EN' || this.languageSelected == 'en' || this.languageSelected == 'En') {
      this.flagLanguageSelected = 'En';
    } else if (this.languageSelected == 'IT' || this.languageSelected == 'it' || this.languageSelected == 'It') {
      this.flagLanguageSelected = 'It';
    } else if (this.languageSelected == 'FR' || this.languageSelected == 'fr' || this.languageSelected == 'Fr') {
      this.flagLanguageSelected = 'Fr';
    } else if (this.languageSelected == 'NL' || this.languageSelected == 'nl' || this.languageSelected == 'Nl') {
      this.flagLanguageSelected = 'Nl';
    }
  }

}
