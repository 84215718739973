import { Injectable } from '@angular/core';

import { SomeProvider } from './some/some';

@Injectable({
  providedIn: 'root'
})

  @Injectable()
  export class AuthGuardService  {
      constructor(private myService: SomeProvider
          ) {
          }
        


      canActivate(): boolean {
     //   console.log(this.myService.isUserLoggedIn)
        return this.myService.isUserLoggedIn;
      }
   
  }