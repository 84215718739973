import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomePageModule)
  },
/*   {
    path: 'carrello-contanti',
    loadChildren: () => import('./carrello-contanti/carrello-contanti.module').then(m => m.CarrelloContantiPageModule)
  }, */
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'new-search-loader',
    loadChildren: () => import('./main-page-ombrelloni/new-search-loader/new-search-loader.module').then(m => m.NewSearchLoaderPageModule)
  },
  {
    path: 'abbonamenti-due',
    loadChildren: () => import('./main-page-ombrelloni/abbonamenti-due/abbonamenti-due.module').then(m => m.AbbonamentiDuePageModule)
  },
  {
    path: 'abbonamenti-uno',
    loadChildren: () => import('./main-page-ombrelloni/abbonamenti-uno/abbonamenti-uno.module').then(m => m.AbbonamentiUnoPageModule)
  },
  {
    path: 'abbonamenti-tre',
    loadChildren: () => import('./main-page-ombrelloni/abbonamenti-tre/abbonamenti-tre.module').then(m => m.AbbonamentiTrePageModule)
  },
  /*  {
     path: 'carrello',
     loadChildren: () => import('./carrello/carrello.module').then(m => m.CarrelloPageModule)
   }, */
  {
    path: 'checkout-ombrellone',
    loadChildren: () => import('./checkout-ombrellone/checkout-ombrellone.module').then(m => m.CheckoutOmbrellonePageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'finalitadati',
    loadChildren: () => import('./finalitadati/finalitadati.module').then(m => m.FinalitadatiPageModule)
  },
  {
    path: 'main-page-ombrelloni',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./main-page-ombrelloni/main-page-ombrelloni.module').then(m => m.MainPageOmbrelloniPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then(m => m.MenuPageModule)
  },
  {
    path: 'pagamenti-braintree-singola',
    loadChildren: () => import('./pagamenti-braintree-singola/pagamenti-braintree-singola.module').then(m => m.PagamentiBraintreeSingolaPageModule)
  },
  {
    path: 'pagamenti-braintree-execute',
    loadChildren: () => import('./pagamenti-braintree-execute/pagamenti-braintree-execute.module').then(m => m.PagamentiBraintreeExecutePageModule)
  },
  {
    path: 'pagamenti-card',
    loadChildren: () => import('./pagamenti-card/pagamenti-card.module').then(m => m.PagamentiCardPageModule)
  },
  {
    path: 'pagamenti-card-singola',
    loadChildren: () => import('./pagamenti-card-singola/pagamenti-card-singola.module').then(m => m.PagamentiCardSingolaPageModule)
  },
  {
    path: 'pagamenti-card-choose',
    loadChildren: () => import('./pagamenti-card-choose/pagamenti-card-choose.module').then(m => m.PagamentiCardChoosePageModule)
  },
  {
    path: 'pagamenti-card-edit',
    loadChildren: () => import('./pagamenti-card-edit/pagamenti-card-edit.module').then(m => m.PagamentiCardEditPageModule)
  },
  {
    path: 'pagamenti-esito',
    loadChildren: () => import('./pagamenti-esito/pagamenti-esito.module').then(m => m.PagamentiEsitoPageModule)
  },
  {
    path: 'profilo',
    loadChildren: () => import('./contact/profilo/profilo.module').then(m => m.ProfiloPageModule)
  },
  {
    path: 'contact/profilo-utente',
    redirectTo: 'tabs/contact/profilo-utente'
  },
  {
    path: 'tabs/carrello',
    redirectTo: 'tabs/carrello'
  },
  {
    path: 'carrello',
    redirectTo: 'tabs/carrello'
  },
  {
    path: 'tabs/carrello-contanti',
    redirectTo: 'tabs/carrello-contanti'
  },
  {
    path: 'carrello-contanti',
    redirectTo: 'tabs/carrello-contanti'
  },
  {
    path: 'home/carrello',
    redirectTo: 'tabs/home/carrello'
  },
  {
    path: 'main-page-ombrelloni/carrello',
    redirectTo: 'tabs/main-page-ombrelloni/carrello'
  },
  {
    path: 'home/pagamenti-esito',
    redirectTo: 'tabs/home/pagamenti-esito'
  },
  {
    path: 'home/pagamenti-card',
    redirectTo: 'tabs/home/carrello/pagamenti-card'
  },
  {
    path: 'home/pagamenti-card-singola',
    redirectTo: 'tabs/home/carrello-contanti/pagamenti-card-singola'
  },
  {
    path: 'home/pagamenti-card/pagamenti-card-edit',
    redirectTo: 'tabs/home/carrello/pagamenti-card/pagamenti-card-edit'
  },
  {
    path: 'home/pagamenti-card/pagamenti-card-choose',
    redirectTo: 'tabs/home/carrello/pagamenti-card/pagamenti-card-choose'
  },

  {
    path: 'main-page-ombrelloni/pagamenti-esito',
    redirectTo: 'tabs/main-page-ombrelloni/pagamenti-esito'
  },
  {
    path: 'main-page-ombrelloni/abbonamenti-uno',
    redirectTo: 'tabs/main-page-ombrelloni/abbonamenti-uno'
  },
  {
    path: 'main-page-ombrelloni/abbonamenti-due',
    redirectTo: 'tabs/main-page-ombrelloni/abbonamenti-due'
  },
  {
    path: 'main-page-ombrelloni/new-search-loader',
    redirectTo: 'tabs/main-page-ombrelloni/new-search-loader'
  },
  {
    path: 'main-page-ombrelloni/abbonamenti-tre',
    redirectTo: 'tabs/main-page-ombrelloni/abbonamenti-tre'
  },
  {
    path: 'main-page-ombrelloni/checkout-ombrellone',
    redirectTo: 'tabs/main-page-ombrelloni/checkout-ombrellone'
  },
  {
    path: 'main-page-ombrelloni/checkout-ombrellone/carrello',
    redirectTo: 'tabs/main-page-ombrelloni/checkout-ombrellone/carrello'
  },
 
  {
    path: 'main-page-ombrelloni/checkout-ombrellone/carrello/pagamenti-card',
    redirectTo: 'tabs/main-page-ombrelloni/checkout-ombrellone/carrello/pagamenti-card'
  },
  {
    path: 'main-page-ombrelloni/checkout-ombrellone/carrello/pagamenti-card-singola',
    redirectTo: 'tabs/main-page-ombrelloni/checkout-ombrellone/carrello/pagamenti-card-singola'
  },
  {
    path: 'main-page-ombrelloni/checkout-ombrellone/carrello/pagamenti-card/pagamenti-card-edit',
    redirectTo: 'tabs/main-page-ombrelloni/checkout-ombrellone/carrello/pagamenti-card/pagamenti-card-edit'
  },
  {
    path: 'main-page-ombrelloni/checkout-ombrellone/carrello/pagamenti-card/pagamenti-card-choose',
    redirectTo: 'tabs/main-page-ombrelloni/checkout-ombrellone/carrello/pagamenti-card/pagamenti-card-choose'
  },
  {
    path: 'recuperapassword',
    loadChildren: () => import('./recuperapassword/recuperapassword.module').then(m => m.RecuperapasswordPageModule)
  },
  {
    path: 'riepilogo',
    loadChildren: () => import('./riepilogo/riepilogo.module').then(m => m.RiepilogoPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'signup-completa',
    loadChildren: () => import('./signup-completa/signup-completa.module').then(m => m.SignupCompletaPageModule)
  },
  {
    path: 'trattamentodati',
    loadChildren: () => import('./trattamentodati/trattamentodati.module').then(m => m.TrattamentodatiPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomePageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
