import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AuthGuardService } from './services/auth-guard.service';
import { ModaleEliminaPrenotazionePageModule } from './modali/modale-elimina-prenotazione/modale-elimina-prenotazione.module';
import { ModaleWebcamPageModule } from './modali/modale-webcam/modale-webcam.module';
import { ModaleInformativaPageModule } from './modali/modale-informativa/modale-informativa.module';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({ schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        ModaleInformativaPageModule,
        IonicModule.forRoot({
            mode: 'ios',
            swipeBackEnabled: false
        }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        FormsModule,
        ModaleEliminaPrenotazionePageModule,
        ModaleWebcamPageModule,
        ReactiveFormsModule], providers: [
        IonicStorageModule,
        AuthGuardService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }

