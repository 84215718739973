import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LangProvider } from '../../services/lang/lang';
import { SomeProvider } from 'src/app/services/some/some';
import { NavParams } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-modale-webcam-prenotazione',
  templateUrl: './modale-webcam.page.html',
  styleUrls: ['./modale-webcam.page.scss'],
})
export class ModaleWebcamPage {
  currentTime: any;
  chiudiLabel = '';
  liveStreamingDataSanitizedUrl: any;
  liveStreamingDataSanitizedM3u: any;
  constructor(
    public lingua: LangProvider,
    private sanitizer: DomSanitizer,
    public navParams: NavParams,
    private modalCtrl: ModalController,
    public myService: SomeProvider) {
  }



  dismiss(): void {
    this.myService.getLiveStreamingData();

    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }


  ionViewWillEnter() {

    this.liveStreamingDataSanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.myService.liveStreamingData);
    this.liveStreamingDataSanitizedM3u = this.sanitizer.bypassSecurityTrustResourceUrl(this.myService.liveStreamingM3u); 
    this.currentTime = this.myService.getTime(new Date());
    this.setLingua();
  }


  setLingua() {

    if (this.lingua.languageSelected == 'IT') {
      this.chiudiLabel = this.lingua.chiudiLabel_IT;
    } else if (this.lingua.languageSelected == 'EN') {
      this.chiudiLabel = this.lingua.chiudiLabel_EN;
    } else if (this.lingua.languageSelected == 'FR') {
      this.chiudiLabel = this.lingua.chiudiLabel_FR;
    } else if (this.lingua.languageSelected == 'NL') {
    }
  }


}
