import { Component } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Platform, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { StorageService } from './services/storage-service.service';
import { HttprequestService } from 'src/app/services/httprequest.service';
import { LangProvider } from './services/lang/lang';
import { SomeProvider } from './services/some/some';
import { AuthGuardService } from './services/auth-guard.service';
import { register } from 'swiper/element/bundle';

register();


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {


  rootPage: any; // Replace tabsPage with Welcome
  tokenValue: boolean = false; //qui verifico il token dell'utente: se ok, manda ai servizi altrimenti alla login
  platformOs: string = "";
  versionNr: string = "3.1.2"; // release attuale 2.0.002
  nav: any;
  alertAppObsoletaLabel = "";
  alertAppObsoletaMessLabel = "";
  alertAppInAggiornamentoLabel = "";
  alertAppInAggiornamentoMessLabel = "";


  constructor(
    private platform: Platform,
    private router: Router,
    private lingua: LangProvider,
    private myService: SomeProvider,
    private alertCtrl: AlertController,
    private http: HttprequestService,
    private storage: StorageService,
    private authGuardService: AuthGuardService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.setLingua();
    this.platform.ready().then(() => {
      SplashScreen.hide();
    });
  }


  async checkAppInManutenzione() {

    this.http.doGetTimeout(this.myService.apiUrl + "/singlefin-ws/rest/public/users/getParametroInt?idParametro=1005").subscribe(data => {
      if (data != '' && data != null && data != undefined) {

        if (data == 1) {
          this.myService.appInManutenzione = true;
          this.alertAppInManutenzione();
          this.logout();

        } else {
          this.myService.appInManutenzione = false;
        }

      }

    }, error => {
      console.log(error);
    });
  }







  setLingua() {
    this.storage.get('locale_lang').then((val) => {

      //  console.log(val);
      //  console.log("Stato Token Dentro App Component: " + val);
      if (val != undefined && val != null && val != '') {
        this.lingua.languageSelected = val;
      }
      else {

        this.storage.set('locale_lang', 'IT').then((val) => {
          this.lingua.languageSelected = 'IT';
        });


      }
      if (this.lingua.languageSelected == 'IT') {
        this.alertAppObsoletaLabel = this.lingua.alertAppObsoleta_IT;
        this.alertAppObsoletaMessLabel = this.lingua.alertAppObsoletaMess_IT;
        this.alertAppInAggiornamentoLabel = this.lingua.alertAppInAggiornamento_IT;
        this.alertAppInAggiornamentoMessLabel = this.lingua.alertAppInAggiornamentoMess_IT;
      } else if (this.lingua.languageSelected == 'EN') {
        this.alertAppObsoletaLabel = this.lingua.alertAppObsoleta_EN;
        this.alertAppObsoletaMessLabel = this.lingua.alertAppObsoletaMess_EN;
        this.alertAppInAggiornamentoLabel = this.lingua.alertAppInAggiornamento_EN;
        this.alertAppInAggiornamentoMessLabel = this.lingua.alertAppInAggiornamentoMess_EN;
      } else if (this.lingua.languageSelected == 'FR') {
        this.alertAppObsoletaLabel = this.lingua.alertAppObsoleta_FR;
        this.alertAppObsoletaMessLabel = this.lingua.alertAppObsoletaMess_FR;
        this.alertAppInAggiornamentoLabel = this.lingua.alertAppInAggiornamento_FR;
        this.alertAppInAggiornamentoMessLabel = this.lingua.alertAppInAggiornamentoMess_FR;
      } else if (this.lingua.languageSelected == 'NL') {
        this.alertAppObsoletaLabel = this.lingua.alertAppObsoleta_NL;
        this.alertAppObsoletaMessLabel = this.lingua.alertAppObsoletaMess_NL;
        this.alertAppInAggiornamentoLabel = this.lingua.alertAppInAggiornamento_NL;
        this.alertAppInAggiornamentoMessLabel = this.lingua.alertAppInAggiornamentoMess_NL;
      }

      this.checkAppInManutenzione();
      this.checkAppVersion();


    });

  }



  isFbLoginIosAvailable() {
    var headers = new Headers();
    // headers.append('Authorization', 'Bearer ' + this.tokenValue);
    /* 
        this.http.get(this.myService.apiUrl + "/singlefin-ws/rest/public/users/getParametroInt?idParametro=100", requestOptions).subscribe(data => {
          // this.myService.isPlatformIos = data== 1 ? true : false;
          this.myService.isLoginFbIosAvailable = data == 1 ? true : false;
    
          //console.log("maxGgAbbonamentoPrenotabili: " + this.maxGgAbbonamentoPrenotabili);
        }, error => {
          // console.log(error.status);
          if (error.status == 0) {
          } else {
          }
    
          //console.log(error);
        }); */


  }
  isFbLoginAndroidAvailable() {
    /*  var headers = new Headers();
     // headers.append('Authorization', 'Bearer ' + this.tokenValue);
     const requestOptions = new RequestOptions({ headers: headers });
 
     this.http.get(this.myService.apiUrl + "/singlefin-ws/rest/public/users/getParametroInt?idParametro=1040", requestOptions).subscribe(data => {
       this.myService.isLoginFbAndroidAvailable = data == 1 ? true : false;
 
       //console.log("maxGgAbbonamentoPrenotabili: " + this.maxGgAbbonamentoPrenotabili);
     }, error => {
       // console.log(error.status);
       if (error.status == 0) {
       } else {
       }
 
       //console.log(error);
     }); */


  }


  checkAppVersion() {
    this.myService.isEliminaAccountDisponibile();
    if (this.platform.is('android')) {
      this.platformOs = 'android';
      this.myService.isPlatformAndroid = true;
      this.isFbLoginAndroidAvailable();
      this.myService.isPlatformIos = false;
    } else if (this.platform.is('ios')) {
      this.myService.isRistoranteAttivoFunc();
      this.isFbLoginIosAvailable();
      this.myService.isPlatformIos = true;
      this.myService.isPlatformAndroid = false;
      this.platformOs = 'ios';
    } else {
      this.platformOs = 'other';

    }

    /*   this.platform.registerBackButtonAction(() => {
      }, 1); */


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');

    let postData = {
      version: this.versionNr,
      platform: this.platformOs
    }
    //console.log(postData);




    this.http.doPostApplicationJson(this.myService.apiUrl + "/singlefin-ws/rest/public/version/checkVersion", postData)
      .subscribe(data => {

        let tempdata = data;
        //console.log(tempdata);
        if (tempdata.result === false) {
          this.myService.isVersionObsoleta = true;
          this.alertVersionOutdate();
          this.logout();
          //  this.router.navigate(['/welcome']);

        } else {

          this.myService.isVersionObsoleta = false;
          this.storage.get('token').then((val) => {
            //  console.log("Stato Token Dentro App Component: " + val);
            if (val != undefined && val != null && val != '') {

              this.tokenValue = true;
              //  console.log(this.tokenValue);
              this.myService.isUserLoggedIn = true;
              this.myService.entraIsClicked = true;
              this.router.navigate(['/tabs']);
              this.authGuardService.canActivate();
            }
            else {
              this.myService.entraIsClicked = false;
              this.router.navigate(['/welcome']);
              this.authGuardService.canActivate();
            }
          });
        }
      }, error => {

      })
  }

  logout() {
    this.storage.set('showModale', '');
    this.myService.isUserLoggedIn = false;
    this.storage.set('token', '').then((val) => {

    });

    this.storage.set('username', '').then((val) => {

    });

    this.router.navigate(['/welcome']);


  }

  async alertVersionOutdate() {   // App da aggiornare
    const alert = await this.alertCtrl.create({
      header: this.alertAppObsoletaLabel,
      message: this.alertAppObsoletaMessLabel,
      buttons: ['OK']
    });

    await alert.present();

  }
  async alertAppInManutenzione() {   // App in manutenzione
    const alert = await this.alertCtrl.create({
      header: this.alertAppInAggiornamentoLabel,
      message: this.alertAppInAggiornamentoMessLabel,
      buttons: ['OK']
    });

    await alert.present();

  }

}
