import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModaleEliminaPrenotazionePageRoutingModule } from './modale-elimina-prenotazione-routing.module';

import { ModaleEliminaPrenotazionePage } from './modale-elimina-prenotazione.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModaleEliminaPrenotazionePageRoutingModule
  ],
  declarations: [ModaleEliminaPrenotazionePage]
})
export class ModaleEliminaPrenotazionePageModule {}
