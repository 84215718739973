<ion-header translucent="" role="banner">
  <ion-toolbar>

    <ion-label class="titolo-toolbar">
      ELIMINA PRENOTAZIONE
    </ion-label>
<!--     <ion-button size="small"class="modale__chiudi" fill="outline" (click)="dismiss()">{{chiudiLabel | uppercase}} </ion-button> -->
  </ion-toolbar>
</ion-header>


<ion-content class="ion-padding" id="modale-elimina-ombrellone">
  <img style="display: block; margin-left: auto; margin-right: auto;  margin-bottom: 50px;    margin-top: 16px; "
    src="assets/imgs/logo.png" class="logo" />
  <div style="background: brown;
padding: 25px;">

    <h1 style="font-size: 20px !important; color: white; text-transform: uppercase; text-align: center;">Sei sicuro di
      voler eliminare la prenotazione<br>
      <span *ngIf="tipoPrenotazione == 'eliminaOmbrellone' ">
        {{this.dataPrenotazioneInizio}} {{this.dataPrenotazioneFine}} 
      </span>

      <span *ngIf="tipoPrenotazione == 'eliminaRistorante'">
        {{this.dataRistorante}}

    </span>
  </h1>
    <div style="display: flex;">

      <ion-button size="small"(click)="dismiss()" style="width:50%;background: lightgray; border: 2px solid white; color: #018fd6"
        color="info" expand=" ">
        {{this.annullaLabel | uppercase}} </ion-button>

      <ion-button size="small"(click)="eliminaPrenotazione()"
        style="width:50% ;background: #F44336 !important; border: 2px solid red;" color="danger" expand="full">
        {{this.confermaLabel | uppercase}} </ion-button>

    </div>

  </div>




</ion-content>