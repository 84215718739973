export const serviziRest = {
	"applicationServer": "/singlefin-ws/",
	"isLiveStreamingAvailableiOS": "/singlefin-ws/rest/parametro/getParametroInt?idParametro=27",
	"isLiveStreamingAvailableAndroid": "/singlefin-ws/rest/parametro/getParametroInt?idParametro=29",
	"getLiveStreamingData": "/singlefin-ws/rest/parametro/getParametroString?idParametro=28",
	"getLiveStreamingM3u": "/singlefin-ws/rest/parametro/getParametroString?idParametro=30",
	"bloccaOmbrellone": "/singlefin-ws/rest/ombrellone/bloccaOmbrellone",
	"liberaParzialmenteOmbrellone": "/singlefin-ws/rest/ges/prenotazioneOmbrellone/liberaParzialmentePrenotazioneOmbrellone",
	"braintreeGenToken": "/singlefin-ws/rest/braintree/getClientToken",
	"braintreePay": "/singlefin-ws/rest/ordine/payBraintree",
	"completaOrdine": "/singlefin-ws/rest/ordine/completaOrdine",
	"creaElementoBar": "/singlefin-ws/rest/ges/elementoBar/createElementoBar",
	"currentServer": "localServer",
	"deleteElementoBar": "/singlefin-ws/rest/ges/elementoBar/deleteElementoBar",
	"deleteUtente": "/singlefin-ws/rest/utente/deleteUtente",
	"deletePrenotazioneOmbr": "/singlefin-ws/rest/prenotazioneOmbrellone/deletePrenotazioneContantiUtente",
	"deletePrenotazioneRist": "/singlefin-ws/rest/prenotazioneRistorante/deletePrenotazioneRistorante",
	"getAllElementoBar": "/singlefin-ws/rest/ges/categoriaBar/getAllCategoriaBar",
	"getCardsList": "/singlefin-ws/rest/stripe/getCardsList",
	"getDefaultCard": "/singlefin-ws/rest/stripe/getDefaultCardinfo",
	"getDisponibilitaMassima": "/singlefin-ws/rest/ges/disponibilitaRistorante/getDisponibilitaMassima",
	"getDisponibilitaRistorante": "/singlefin-ws/rest/ristorante/getDisponibilitaRistorante?",
	"getInfoStandard": "/singlefin-ws/rest/ordine/getOrdini",
	"getListaBar": "/singlefin-ws/rest/bar/getListaBar",
	"getListaBarPerCategoria": "/singlefin-ws/rest/bar/getListaBarPerCategoria",
	"getListaUtenti": "/singlefin-ws/rest/ges/utente/getAllUtente",
	"getMaxElemento": "/singlefin-ws/rest/bar/getMaxElemento",
	"getMenu": "/singlefin-ws/rest/menu/getMenu?data=",
	"getPopupDisponibile": "/singlefin-ws/rest/parametro/getParametroInt?idParametro=1006",
	"getOrdine": "/singlefin-ws/rest/ordine/getOrdine?idOrdine=",
	"getPrenotazioniContantiDisponibili": "/singlefin-ws/rest/prenotazioneOmbrellone/getPrenotazioniContantiDisponibili",

	"getMenuPublic": "/singlefin-ws/rest/public/menu/getMenu?data=",
	"getNotizieDisponibili": "/singlefin-ws/rest/notizia/getNotizieDisponibili",
	"getNotiziePublic": "/singlefin-ws/rest/public/notizia/getNotizieDisponibili",
	"getOmbrelloni": "/singlefin-ws/rest/ombrellone/getOmbrelloni?data=",
	"getOmbrelloniPeriodo": "/singlefin-ws/rest/ombrellone/getOmbrelloniPeriodo?dataInizio=",
	"getParcheggiDisponibili": "/singlefin-ws/rest/parcheggio/getParcheggiDisponibili?data=",
	"getPrezziOmbrellone": "/singlefin-ws/rest/ombrellone/getPrezziOmbrellone?idOmbrellone=",
	"getPrezziOmbrelloni": "/singlefin-ws/rest/prezzoOmbrellone/getPrezziQuotidiani",
	"getPrezziServizi": "/singlefin-ws/rest/servizioOmbrellone/getServizi",
	"getSdraioDisponibili": "/singlefin-ws/rest/servizioOmbrellone/getServizioDisponibile?idServizio=1",
	"getUrlStreaming": "/singlefin-ws/rest/parametro/getUrlStreaming",
	"getUtente": "/singlefin-ws/rest/utente/getUtente",
	"iniziaOrdine": "/singlefin-ws/rest/ordine/iniziaOrdine",
	"isPaypalDisponibile": "/singlefin-ws/rest/parametro/isPaypalAvailable",
	"localServer": "https://localhost:8180/singlefin-ws/",
	"loginFb": "/singlefin-ws/rest/public/users/fbAccess",
	"loginManuale": "/singlefin-ws/rest/public/users/mLogin",
	"pagaAncheContanti": "/singlefin-ws/rest/parametro/getPagaAncheContanti",
	"pagaSoloContanti": "/singlefin-ws/rest/parametro/getPagaSoloContanti",
	"productionServer": "/singlefin-ws/",
	"recuperaPassword": "/singlefin-ws/rest/public/users/requestPassReset",
	"registrazioneManuale": "/singlefin-ws/rest/public/users/mRegister",
	"removeCard": "/singlefin-ws/rest/stripe/removeCard",
	"sbloccaOmbrellone": "/singlefin-ws/rest/ombrellone/sbloccaOmbrellone",
	"setUtente": "/singlefin-ws/rest/utente/updateUtente",
	"stripePay": "/singlefin-ws/rest/ordine/payStripe",
	"stripePayOld": "/singlefin-ws/rest/ordine/pay",
	"dividiOrdinePagaPrenotazioneOmbrelloneContantiPaypal": "/singlefin-ws/rest/ordine/dividiOrdinePagaPrenotazioneOmbrelloneContantiPaypal",
	"dividiOrdineGenerateStripeIntent": "/singlefin-ws/rest/ordine/dividiOrdineGenerateStripeIntent",
	"stripePayWithIntent": "/singlefin-ws/rest/ordine/payStripeWithIntent",
	"stripeRetrieveClientSecret": "/singlefin-ws/rest/ordine/generateStripeIntent",
	"stripeRetrieveSetupClientSecret": "/singlefin-ws/rest/ordine/generateStripeSetupIntent",
	"updateDefaultCard": "/singlefin-ws/rest/stripe/updateDefaultCard",
	"updateDefaultCard2": "/singlefin-ws/rest/stripe/updateDefaultCard",
	"updateDisponibilitaRistorante": "/singlefin-ws/rest/ges/disponibilitaRistorante/updateDisponibilitaRistorante",
	"updateElementoBar": "/singlefin-ws/rest/ges/elementoBar/updateElementoBar",
	"updateOrdineConcluso": "/singlefin-ws/rest/ordine/updateOrdineConcluso",
	"updateUtente": "/singlefin-ws/rest/ges/utente/updateUtente",
	"valutaOrdine": "/singlefin-ws/rest/ordine/valutaOrdine",
	"calcolaInizioStagione": "/singlefin-ws/rest/public/users/getParametroString?idParametro=16",
	"calcolaFineStagione": "/singlefin-ws/rest/public/users/getParametroString?idParametro=17",
	"calcolaInizioStagioneRistoranteUno": "/singlefin-ws/rest/public/users/getParametroString?idParametro=18",
	"calcolaFineStagioneRistoranteUno": "/singlefin-ws/rest/public/users/getParametroString?idParametro=19",
	"calcolaInizioStagioneRistoranteDue": "/singlefin-ws/rest/public/users/getParametroString?idParametro=188",
	"calcolaFineStagioneRistoranteDue": "/singlefin-ws/rest/public/users/getParametroString?idParametro=189",
	"payPaypal": "/singlefin-ws/rest/ordine/payPaypal",
	"completaOrdinePaypal": "/singlefin-ws/rest/ordine/completaOrdinePaypal",
	"getPrivacyUrlIta": "/singlefin-ws/rest/public/users/getParametroString?idParametro=34",
	"getPrivacyUrlEng": "/singlefin-ws/rest/public/users/getParametroString?idParametro=35"
}