<ion-header translucent="" role="banner">
  <ion-toolbar>

    <ion-label class="titolo-toolbar display__flex justify__center">
      LIVE WEBCAM
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content id="modale-webcam">
  <div class="padding__top__16">


    <img src="assets/imgs/logo.png" class="logo logo__style" />

    <div class="display__flex align__items__center flex__direction__column">
      <h1 class="webcam__cta weather__text text__white text__bold text__center font__1_15__rem"> Single Fin Tuscany,
        {{this.currentTime}}</h1>

      <div class="width__100"
        *ngIf="this.myService.isLiveStreamingDisponibileAndroid && this.myService.isPlatformAndroid">

        <iframe id="streamingIframe" width="100%" height="auto" frameborder="0" marginheight="0" marginwidth="0"
          scrolling="no" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen
          *ngIf="this.liveStreamingDataSanitizedUrl" [src]="this.liveStreamingDataSanitizedUrl">

        </iframe>

      </div>
      <video width="100%" height="auto"
        *ngIf="this.myService.isLiveStreamingDisponibileiOS && this.myService.isPlatformIos" id="videoID" preload="auto"
        autoplay="autoplay" controls>
        <source [src]="this.liveStreamingDataSanitizedM3u" type="video/mp4" />
      </video>


    </div>
    <div>

      <ion-button size="small"expand="block" fill="outline" (click)="dismiss()"
        class="height__2_25rem margin__0 width__100 button__outline__white">
        {{this.chiudiLabel | uppercase}}
      </ion-button>
    </div>
  </div>

</ion-content>