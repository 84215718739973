<ion-content class="ion-padding">
  <div class="modale__flex">
    <h1>
      <a target="_blank" [href]="privacyPolicyIta">Privacy Policy - Italiano</a>
    </h1>
    <h1>
      <a target="_blank" [href]="privacyPolicyEng">Privacy Policy - English</a>
    </h1>

    <ion-button size="small"style="margin-top: 16px;" class="width__100 margin__16 height__2_25rem button__outline__secondary"
      expand="block" fill="outline" (click)="dismiss()">
      {{this.chiudiLabel | uppercase}}</ion-button>
  </div>
</ion-content>